export enum EquityBasketStatus {
  DRAFT = 'DRAFT',
  COMPLIANT = 'COMPLIANT',
  NON_COMPLIANT = 'NON_COMPLIANT',
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  LIVE = 'LIVE',
  ARCHIVED = 'ARCHIVED',
}
