import AnalyticsStore from '@/store/modules/AnalyticsStore';
import {
  AnalysisStep,
  AnalysisSubstep,
  CLUSTERING_ANALYSIS,
  CONSTITUENT_RISK,
  FACTSHEET,
  FOLDER,
  PERFORMANCE_ATTRIBUTION,
  EQUITY_BASKET,
} from '@/types/analytics/AnalysisStep';
import { FACTOR_DECOMPOSITION } from '@/types/analytics/FactorDecomposition';
import {
  PORTFOLIO_CONSTRUCTION,
  PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER,
  PORTFOLIO_CONSTRUCTION_PERF_ATTRIB,
} from '@/types/analytics/PortfolioConstruction';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { useFeatureFlag } from './useFeatureFlag';
import useRouteChecks from './useRouteChecks';
import { usePerformanceContributionDates } from './usePerformanceContributionData';
import { Route } from 'vue-router';
import { useIndexUniqueIdentifier, useStrategyIdentifier } from './useCorrectIdentifier';

const activeAnalysisStep: ComputedRef<AnalysisStep> = computed(() => AnalyticsStore.activeAnalysisStep);

export default function (): {
  activeAnalysisStep: ComputedRef<AnalysisStep>;
} {
  return {
    activeAnalysisStep,
  };
}

const createComposableForAnalysisStep = (step: AnalysisStep) => {
  const availableAnalysisSubsteps = step.substeps;
  const activeAnalysisSubstep = ref<AnalysisSubstep | null>(null);
  const findSubstepByRoute = (route: string): AnalysisSubstep | undefined => {
    return step.substeps.find((s): boolean => s.path === route);
  };

  return (): {
    availableAnalysisSubsteps: AnalysisSubstep[];
    activeAnalysisSubstep: Ref<AnalysisSubstep | null>;
    findSubstepByRoute: (route: string) => AnalysisSubstep | undefined;
  } => {
    return {
      availableAnalysisSubsteps,
      activeAnalysisSubstep,
      findSubstepByRoute,
    };
  };
};

export const usePortfolioConstructionAnalysisSteps = createComposableForAnalysisStep(PORTFOLIO_CONSTRUCTION);
export const useFactorDecompositionAnalysisSteps = createComposableForAnalysisStep(FACTOR_DECOMPOSITION);
export const useConstituentRiskAnalysisSteps = createComposableForAnalysisStep(CONSTITUENT_RISK);
export const useEquityBasketAnalysisSteps = createComposableForAnalysisStep(EQUITY_BASKET);

export function useActiveSubstep() {
  const { activeAnalysisSubstep } = usePortfolioConstructionAnalysisSteps();

  const isOnFrontierSubstep = computed(() => {
    return (
      activeAnalysisStep.value === PORTFOLIO_CONSTRUCTION &&
      activeAnalysisSubstep.value != null &&
      activeAnalysisSubstep.value.path === PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER.path
    );
  });

  const isOnPerfAttribSubstep = computed(() => {
    return (
      activeAnalysisStep.value === PORTFOLIO_CONSTRUCTION &&
      activeAnalysisSubstep.value != null &&
      activeAnalysisSubstep.value.path === PORTFOLIO_CONSTRUCTION_PERF_ATTRIB.path
    );
  });

  return {
    isOnFrontierSubstep,
    isOnPerfAttribSubstep,
  };
}

export function useAnalysisStepItems(route: Ref<Route>, isAnalysisToolModal: boolean = false) {
  const indexUniqueIdentifier = useIndexUniqueIdentifier();
  const { isPortfolioPage, isNavPages, isStrategyPage } = useRouteChecks(route);

  const strategyIdentifier = useStrategyIdentifier();

  const isDemoPortfolio = computed(() => {
    if (!indexUniqueIdentifier.value) return false;
    return indexUniqueIdentifier.value.includes('demo_portfolio');
  });

  const perfDates = usePerformanceContributionDates(strategyIdentifier, {
    enabled: computed(() => !!strategyIdentifier.value && !isPortfolioPage.value),
  });

  const {
    canSwitchPortfolios,
    shouldConstituentRiskBeVisible,
    shouldPerformanceContributionBeVisible,
    shouldClusteringAnalysisBeVisible,
    shouldFactorDecompositionBeVisible,
    shouldPreventStrategyFactsheetAccess,
    hasEquityBasketAccess,
  } = useFeatureFlag();

  const availableAnalysisStepItems = computed((): AnalysisStep[] => {
    const retval: AnalysisStep[] = [];

    if (canSwitchPortfolios.value) {
      retval.push(FOLDER);
    }

    if ((isAnalysisToolModal || isPortfolioPage.value) && !isDemoPortfolio.value) {
      retval.push(PORTFOLIO_CONSTRUCTION);
    }

    // Currently shouldClusteringAnalysisBeVisible would only be true to core
    if (
      (isAnalysisToolModal || isPortfolioPage.value) &&
      !isDemoPortfolio.value &&
      shouldClusteringAnalysisBeVisible.value
    ) {
      retval.push(CLUSTERING_ANALYSIS);
    }

    // Currently shouldFactorDecompositionBeVisible would only be true to core
    if (!isDemoPortfolio.value && shouldFactorDecompositionBeVisible.value) {
      retval.push(FACTOR_DECOMPOSITION);
    }

    if ((isAnalysisToolModal || isPortfolioPage.value) && shouldConstituentRiskBeVisible.value) {
      retval.push(CONSTITUENT_RISK);
    }

    if (
      (isPortfolioPage.value && !isDemoPortfolio.value) ||
      (!isPortfolioPage.value && !shouldPreventStrategyFactsheetAccess.value)
    ) {
      retval.push(FACTSHEET);
    }

    if (perfDates.data.value?.length && shouldPerformanceContributionBeVisible.value) {
      retval.push(PERFORMANCE_ATTRIBUTION);
    }

    if (hasEquityBasketAccess.value) {
      retval.push(EQUITY_BASKET);
    }

    return retval;
  });

  const availableAnalysisStepItemsBofA = computed((): AnalysisStep[] => {
    const retval: AnalysisStep[] = [];

    if (canSwitchPortfolios.value) {
      retval.push(FOLDER);
    }

    // if it's a portfolio means
    retval.push(PORTFOLIO_CONSTRUCTION);

    // Currently shouldClusteringAnalysisBeVisible would only be true to core
    if (shouldClusteringAnalysisBeVisible.value) {
      retval.push(CLUSTERING_ANALYSIS);
    }

    // Currently shouldFactorDecompositionBeVisible would only be true to core
    if (shouldFactorDecompositionBeVisible.value) {
      retval.push(FACTOR_DECOMPOSITION);
    }

    if (shouldConstituentRiskBeVisible.value) {
      retval.push(CONSTITUENT_RISK);
    }

    if (isPortfolioPage.value || isStrategyPage.value) {
      // Hide factsheet on nav pages
      // TODO: WAA-8562 - Showing factsheet or not on sidebar should depends on itemUnderAnalysis instead.
      // TODO: WAA-8562 - Reset itemUnderAnalysis when user navigate outside analytics page.
      // This is a temporary fix.
      if (!isNavPages.value) {
        retval.push(FACTSHEET);
      }
    }

    if (perfDates.data.value?.length && shouldPerformanceContributionBeVisible.value) {
      retval.push(PERFORMANCE_ATTRIBUTION);
    }

    if (hasEquityBasketAccess.value) {
      retval.push(EQUITY_BASKET);
    }

    return retval;
  });

  return {
    availableAnalysisStepItems,
    availableAnalysisStepItemsBofA,
  };
}
