import { RouteName } from '@/constants/RouteName';
import { Location } from 'vue-router';

export function useResolveFactsheetRoutes() {
  const getStrategyFactsheetLocation = (code: string): Location => ({
    name: RouteName.STRATEGY_FACTSHEET,
    params: {
      indexUniqueIdentifier: code,
    },
  });

  const getEditStrategyLocation = (timeSeriesEntityId: string): Location => ({
    name: RouteName.EDIT_STRATEGY,
    params: {
      id: timeSeriesEntityId,
    },
  });

  const getStrategyFactsheetPdfLocation = (code: string): Location => ({
    name: RouteName.STRATEGY_FACTSHEET_PDF,
    params: {
      indexUniqueIdentifier: code,
    },
  });

  const getPerformanceContributionLocation = (code: string): Location => ({
    name: RouteName.PERFORMANCE_ATTRIBUTION,
    params: {
      indexUniqueIdentifier: code,
    },
  });

  const getPerformanceContributionPdfLocation = (code: string, substep: string): Location => ({
    name: RouteName.PERFORMANCE_ATTRIBUTION_PDF,
    params: {
      indexUniqueIdentifier: code,
      substep,
    },
  });

  const getPortfolioFactsheetLocation = (slug: string): Location => ({
    name: RouteName.PORTFOLIO_FACTSHEET,
    params: {
      indexUniqueIdentifier: slug,
    },
  });

  const getPortfolioFactsheetPdfLocation = (slug: string): Location => ({
    name: RouteName.PORTFOLIO_FACTSHEET_PDF,
    params: {
      indexUniqueIdentifier: slug,
    },
  });

  return {
    getStrategyFactsheetLocation,
    getEditStrategyLocation,
    getStrategyFactsheetPdfLocation,
    getPerformanceContributionLocation,
    getPerformanceContributionPdfLocation,
    getPortfolioFactsheetLocation,
    getPortfolioFactsheetPdfLocation,
  };
}
