import { createGlobalState } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useGetBlmViews } from './queries/useBlmView';
import { useRouteRef } from './useRouter';
import useRouteChecks from './useRouteChecks';

/**
 * This global state is used to track the current blmView id and
 * whether or not blmViw is active.
 */
const useBlmState = createGlobalState(() => {
  const storeBlmViewId = ref<string | undefined>(undefined);
  const isBlmActive = ref(false);

  return {
    storeBlmViewId,
    isBlmActive,
  };
});

/**
 * This composable is used to track the current blmView and its id,
 * the list of blmViews, and whether or not blmView is active.
 */
export default function useBlm() {
  const state = useBlmState();
  const query = useGetBlmViews();

  const route = useRouteRef();
  const { isOnSimulation, isOnEfficientFrontier } = useRouteChecks(route);

  const isOnValidPage = computed(() => isOnSimulation.value || isOnEfficientFrontier.value);

  /**
   * if isBlmActive is false, return null. There is no storeBlmView if BLM View is not active.
   */
  const storeBlmView = computed(() => {
    if (!state.isBlmActive.value) return null;

    return query.data.value?.find((view) => view.id === state.storeBlmViewId.value) ?? null;
  });

  // TODO: Consider moving get last used BLM graph to here
  // const activateLastUsedBlm = () => {};

  return {
    ...state,
    query,
    storeBlmView,
    isOnValidPage,
  };
}
