import { Currency } from '@/constants/Currency';
import { FxConversion } from '@/constants/FxConversion';
import { AnalyticsTrackTypeConstants } from '@/types/AnalyticsTrackTypeConstants';
import { IPortfolioTreeSubportfolio, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { computed, ComputedRef, ref } from 'vue';
import { useAllPortfoliosBySlug, useAllStrategiesByCode } from './queries/useDataDiscovery';
import useTranslation from './useTranslation';

/**
 * TODO: set these to undefined at first and when item under analysis is changed
 * so that we can prevent unnecessary network calls when these are not set yet
 */
const toCurrency = ref(Currency.USD);
const fxType = ref(FxConversion.NOT_CONVERTED);

const setToCurrency = (newVal: Currency) => {
  toCurrency.value = newVal;
};

const setFxType = (newVal: FxConversion) => {
  fxType.value = newVal;
};

/**
 * Changes the fxType or toCurrency for every child of selected component only if isInherited is set to true.
 *
 * @param portfolioTree Subportfolio to go through
 * @param params Portfolio Tree fx conversion item to change and its value
 */
const changeFxConversionParam = (
  portfolioTree: IPortfolioTreeSubportfolio,
  { toCurrency, fxType }: { toCurrency?: Currency; fxType?: FxConversion },
): void => {
  for (const component of portfolioTree.components) {
    // We should not change the toCurrency and fxType of pointer portfolios
    // TODO: Check with christophe, should we stop at pointerSlug or stop at type Constituent
    if (isPortfolioTreeSubportfolio(component) && component.pointerSlug) continue;

    if (toCurrency !== component.toCurrency) {
      component.toCurrency = toCurrency;
    }
    if (fxType !== component.fxType && component.isInherited) {
      component.fxType = fxType;
    }
    if (isPortfolioTreeSubportfolio(component)) {
      changeFxConversionParam(component, { fxType, toCurrency });
    }
  }
};

/**
 * This function is designed to return a benchmark's name, plus minimal FX conversion information
 */
export function useBenchmarkFxConversionName(
  comparisonBenchmark: ComputedRef<{
    codeOrSlug: string;
    type: AnalyticsTrackTypeConstants;
  }>,
) {
  const allStrategiesByCode = useAllStrategiesByCode();
  const allPortfolioTreesBySlug = useAllPortfoliosBySlug();
  const { translate } = useTranslation();

  return computed(() => {
    const benchmarkName =
      comparisonBenchmark.value.type === AnalyticsTrackTypeConstants.PORTFOLIO
        ? allPortfolioTreesBySlug.data.value?.get(comparisonBenchmark.value.codeOrSlug)?.name ?? ''
        : allStrategiesByCode.data.value?.get(comparisonBenchmark.value.codeOrSlug)?.shortName ?? '';

    if (fxType.value === FxConversion.NOT_CONVERTED) return benchmarkName;

    // If the benchmark name includes the currency, don't show the FX Conversion
    if (benchmarkName.includes(toCurrency.value)) return benchmarkName;

    if (fxType.value === FxConversion.MONTHLY_HEDGED) {
      // For a strategy benchmark, if its original currency is the same the applied currency, then only show the currency
      if (comparisonBenchmark.value.type === AnalyticsTrackTypeConstants.TRACK) {
        const currency = allStrategiesByCode.data.value?.get(comparisonBenchmark.value.codeOrSlug)?.currency ?? '';
        if (currency === toCurrency.value) return `${benchmarkName} (${toCurrency.value})`;
      }

      // Otherwise, show the fx conversion applied
      return `${benchmarkName} (${toCurrency.value} ${translate({
        path: 'GLOBAL.FX_CONVERSION.MONTHLY_HEDGED_SHORT',
      })})`;
    }
    // Just show the applied currency for Unhedged
    return `${benchmarkName} (${toCurrency.value})`;
  });
}

export default function (): {
  toCurrency: ComputedRef<Currency>;
  fxType: ComputedRef<FxConversion>;
  setToCurrency: (newVal: Currency) => void;
  setFxType: (newVal: FxConversion) => void;
  changeFxConversionParam: (
    portfolioTree: IPortfolioTreeSubportfolio,
    { fxType, toCurrency }: { fxType?: FxConversion; toCurrency?: Currency },
  ) => void;
} {
  return {
    toCurrency: computed(() => toCurrency.value),
    setToCurrency,
    fxType: computed(() => fxType.value),
    setFxType,
    changeFxConversionParam,
  };
}
