<template>
  <div v-if="portfolio">
    <div
      class="list-group-item-heading"
      :class="{
        'text-ellipsis': isPdf || !linkShouldWrap || useEllipsis,
      }"
    >
      <b-link
        v-if="!portfolio.slug.includes('demo_portfolio')"
        :to="getPortfolioFactsheetLocation(portfolio.slug)"
        rel="noopener noreferrer"
        @click.stop
      >
        <!-- we display a dash when there's no name, so that the user may still click on the hyperlink -->
        {{ portfolio.name || '-' }}
      </b-link>
      <span v-else>{{ portfolio.name }}</span>
    </div>
    <div
      v-if="shouldShowSecondLine"
      class="text-wrap-xs"
      data-testid="track-subtext"
    >
      {{ numStrategiesSubText }} | <span :style="currencyColor">{{ additionalSubtext }}</span> |
      <span :style="portfolioColor">{{ translate({ path: 'GLOBAL.PORTFOLIO_TYPE_NAME', item: portfolioType }) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import useFxConversion from '@/composables/useFxConversion';
import { useIsPdf } from '@/composables/usePdf';
import { useResolveFactsheetRoutes } from '@/composables/useResolveFactsheetRoutes';
import useTranslation from '@/composables/useTranslation';
import { FxConversion } from '@/constants/FxConversion';
import { SettingsModule } from '@/store/barrel';
import { DataTypes, normalizePortfolioType } from '@/types/setting';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PortfolioSubtext',
  props: {
    portfolio: {
      type: Object as PropType<PortfolioItemResponseDTO>,
      required: true,
    },
    regressorFxType: {
      type: String as PropType<FxConversion>,
      required: false,
    },
    shouldUseFxCurrency: {
      type: Boolean,
      required: false,
      default: true,
    },
    linkShouldWrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    useEllipsis: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowSecondLine: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const { translate } = useTranslation();
    const { toCurrency } = useFxConversion();

    const dataTypeColors = SettingsModule.colors.productType || {};

    const portfolioCurrency = computed(() => props.portfolio.currency ?? 'USD');

    const portfolioType = computed(() => {
      const normalizedType = normalizePortfolioType(props.portfolio);
      return normalizedType;
    });

    const { getPortfolioFactsheetLocation } = useResolveFactsheetRoutes();

    const numStrategiesSubText = computed(() => {
      const count = props.portfolio.numStrategies;
      return `${count} ${translate({
        path: 'GLOBAL.DATABASE_TRANSLATOR.STRATEGY',
        pluralIndex: count === 1 ? 1 : 2,
      })}`;
    });

    const additionalSubtext = computed(() => {
      let subtext = '';
      subtext = `${props.shouldUseFxCurrency ? toCurrency.value : portfolioCurrency.value} `;
      // On the regression page, when fx conversion is applied and it is of type Monthly Hedged, we want to show it
      if (
        props.regressorFxType &&
        toCurrency.value !== portfolioCurrency.value &&
        props.regressorFxType === FxConversion.MONTHLY_HEDGED
      ) {
        subtext = `${subtext} ${translate({
          path: 'GLOBAL.FX_CONVERSION.MONTHLY_HEDGED_SHORT',
        })} `;
      }
      return subtext;
    });

    /**
     * Each strategy type will have their own individual colours
     */
    const portfolioColor = computed(() => {
      if (!portfolioType.value) return '';
      return `color:${dataTypeColors[portfolioType.value]};`;
    });

    /**
     * On the regression page, when the currency is shown, we want to change the color if a Fx Conversion is applied to the regressor
     */
    const currencyColor = computed(() => {
      if (
        !props.regressorFxType ||
        props.regressorFxType === FxConversion.NOT_CONVERTED ||
        portfolioCurrency.value === toCurrency.value
      )
        return '';
      return `color:${dataTypeColors[DataTypes.THEMATIC]};`;
    });

    const isPdf = useIsPdf();

    return {
      getPortfolioFactsheetLocation,
      numStrategiesSubText,
      additionalSubtext,
      portfolioColor,
      portfolioType,
      currencyColor,
      translate,
      isPdf,
    };
  },
});
</script>
