<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-row
      v-if="isMaster && !isOnEquityBasket"
      class="mt-1 mb-3"
    >
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.TYPE' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown
            :disabled="disabled"
            :menu-class="{ 'scroll-md': true, shadow: true }"
          >
            <template #button-content>
              <span :style="isBenchmark ? benchmarkColor : ''">
                {{
                  isBenchmark
                    ? translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: DataTypes.BENCHMARK })
                    : translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: DataTypes.PORTFOLIO })
                }}
              </span>
            </template>
            <b-dropdown-item
              v-for="option of portfolioTypes"
              :key="option.value"
              :active="isBenchmark === option.value"
              @click.stop="isBenchmark = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col
        v-if="showCreatedAt"
        class="d-flex flex-column text-right font-size-sm"
      >
        <span
          v-if="isMaster && createdBy && createdAt"
          class="text-gray"
          align-h="end"
        >
          {{
            translate({
              path: 'MODALS.STATIC_DATA_MODAL.CREATED_BY',
              params: {
                who: createdBy,
                when: createdAt,
              },
            })
          }}
        </span>
        <span
          v-if="isMaster && updatedBy && updatedAt"
          class="text-gray"
          align-h="end"
        >
          {{
            translate({
              path: 'MODALS.STATIC_DATA_MODAL.UPDATED_BY',
              params: {
                who: updatedBy,
                when: updatedAt,
              },
            })
          }}
        </span>
      </b-col>
      <b-col v-else />
    </b-row>

    <b-row
      v-if="!isOnEquityBasket"
      class="mt-1 mb-3"
    >
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.PORTFOLIO_INDEX' })"
          class="no-padding-left mb-0"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
        >
          <IndexSearchBoxSelect
            v-model="portfolioTree.reference"
            :disabled="disabled"
            :dsb-options="dsbOptionsPortfolioIndex"
            :disabled-codes.sync="disabledReferenceCodes"
            :should-show-code-in-button="true"
            timer-id="StaticDataModal reference DSB"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.BENCHMARK' })"
          class="no-padding-left mb-0"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
        >
          <IndexSearchBoxSelect
            v-model="portfolioTree.benchmark"
            :disabled="disabled || (isMaster && isBenchmark)"
            :button-content-override="isMaster && isBenchmark ? '-' : undefined"
            :dsb-options="dsbOptionsBenchmark"
            timer-id="StaticDataModal benchmark DSB"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.PORTFOLIO_CURRENCY' })"
          class="no-padding-left mb-0"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
        >
          <StandardFormDropdown
            v-if="isMaster"
            :menu-class="{ 'scroll-md w-100': true, shadow: true }"
            :disabled="disabled || isConstituentPortfolio"
          >
            <template #button-content>
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: portfolioTree.toCurrency }) }}
            </template>
            <CurrencyDropdownItems>
              <template #default="{ currency }">
                <b-dropdown-item
                  :key="currency"
                  :active="portfolioTree.toCurrency === currency"
                  @click.stop="() => onClickCurrency(currency)"
                >
                  {{ currency }}
                </b-dropdown-item>
              </template>
            </CurrencyDropdownItems>
          </StandardFormDropdown>
          <b-form-input
            v-else
            v-model="portfolioTree.toCurrency"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.FX_CONVERSION.FX_CONVERSION' })"
          class="no-padding-left mb-0"
          label-cols="3"
          label-class="pr-3"
          label-align="left"
        >
          <StandardFormDropdown
            v-if="isMaster && !isOnEquityBasket"
            :disabled="disabled || isConstituentPortfolio"
          >
            <template #button-content>
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: portfolioTree.fxType }) }}
            </template>
            <b-dropdown-item
              v-for="option of fxConversionTypes"
              :key="option"
              :active="portfolioTree.fxType === option"
              @click.stop="() => onClickFxType(option)"
            >
              {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: option }) }}
            </b-dropdown-item>
          </StandardFormDropdown>
          <b-button
            v-else-if="isOnEquityBasket"
            variant="outline-grey"
            class="w-100 text-left text-ellipsis"
            disabled
          >
            {{ translate({ path: 'GLOBAL.FX_CONVERSION', item: FxConversion.UNHEDGED }) }}
          </b-button>
          <b-form-input
            v-else
            v-model="inheritedType"
            :title="inheritedType"
            class="text-ellipsis"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="!isOnEquityBasket"
      class="mb-3"
    >
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.ASSET_CLASS' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled">
            <template #button-content>
              {{
                portfolioTree.assetClass
                  ? translate({ path: 'GLOBAL.ASSET_CLASS_CONSTANTS', item: portfolioTree.assetClass })
                  : translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' })
              }}
            </template>
            <b-dropdown-item
              v-for="option of assetClassTypes"
              :key="option.value"
              :active="portfolioTree.assetClass === option.value"
              @click.stop="portfolioTree.assetClass = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.REGION' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled">
            <template #button-content>
              {{
                portfolioTree.region
                  ? translate({ path: 'GLOBAL.REGION_CONSTANTS', item: portfolioTree.region })
                  : translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' })
              }}
            </template>
            <b-dropdown-item
              v-for="option of regionTypes"
              :key="option.value"
              :active="portfolioTree.region === option.value"
              @click.stop="portfolioTree.region = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="!isOnEquityBasket"
      class="mb-3"
    >
      <b-col cols="6">
        <b-form-group
          :label="translate({ path: 'GLOBAL.FACTOR' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled">
            <template #button-content>
              {{
                portfolioTree.factor
                  ? translate({ path: 'GLOBAL.FACTOR_CONSTANTS', item: portfolioTree.factor })
                  : 'Select one...'
              }}
            </template>
            <b-dropdown-item
              v-for="option of factorTypes"
              :key="option.value"
              :active="portfolioTree.factor === option.value"
              @click.stop="portfolioTree.factor = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        class="mb-3"
      >
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.STYLE' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <StandardFormDropdown :disabled="disabled">
            <template #button-content>
              {{
                portfolioTree.style
                  ? translate({ path: 'GLOBAL.STYLE_CONSTANTS', item: portfolioTree.style })
                  : translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' })
              }}
            </template>
            <b-dropdown-item
              v-for="option of styleTypes"
              :key="option.value"
              :active="portfolioTree.style === option.value"
              @click.stop="portfolioTree.style = option.value"
            >
              {{ option.label }}
            </b-dropdown-item>
          </StandardFormDropdown>
        </b-form-group>
      </b-col>
      <!-- Show the custom inception date for all root components -->
      <b-col
        v-if="isMaster || portfolioTree.pointerSlug"
        cols="6"
      >
        <b-form-group
          :label="translate({ path: 'GLOBAL.DATABASE_TRANSLATOR.INCEPTION_DATE' })"
          label-cols="6"
          label-class="pr-3"
          label-align="left"
          class="mb-0"
        >
          <span class="d-flex">
            <StandardFormDropdown
              id="custom-inception-date"
              ref="dateDropdown"
              data-testid="custom-inception-date"
              :disabled="disabled"
              class="w-100"
              @hidden="onHidden"
            >
              <template #button-content>
                {{ portfolioTree.customInceptionDate ?? translate({ path: 'MODALS.STATIC_DATA_MODAL.SELECT_DATE' }) }}
              </template>
              <InceptionDateCalendar
                :event-bus="eventBus"
                :default-date="portfolioTree.customInceptionDate"
                @close-dropdown="closeDropdown"
                @new-date="onNewCustomInceptionDate"
              />
            </StandardFormDropdown>
            <StandardButton
              v-if="portfolioTree.customInceptionDate"
              :disabled="disabled"
              :icon="'trash-alt'"
              variant="danger"
              class="ml-2"
              @click="removeCustomInceptionDate"
            />
          </span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <b-form-group
          :label="translate({ path: 'MODALS.PROXY_EDIT_ATTRIBUTE_MODAL.PROPERTIES.DESCRIPTION' })"
          :disabled="disabled"
          label-class="pr-3"
          label-align="bottom"
          class="mb-0"
        >
          <b-form-textarea
            v-model.lazy="description"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-mutating-props */
import { computed, defineComponent, PropType, ref, set } from 'vue';
import { FactorConstants } from '@/constants/FactorConstants';
import { Region } from '@/constants/Region';
import { StyleConstants } from '@/constants/StyleConstants';
import { ATTRIBUTE_DATABASE_NAME } from '@/types/strategy';
import { IPortfolioTree, IPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { FxConversion } from '@/constants/FxConversion';
import { translatorForProperty } from '@/utils/strategy';
import useTranslation from '@/composables/useTranslation';
import CurrencyDropdownItems from '@/components/CurrencyDropdownItems.vue';
import useFxConversion from '@/composables/useFxConversion';
import { DataTypes } from '@/types/setting';
import { DSBOptions } from '@/types/DSBOptions';
import { DateFormat, InputDateFormat } from '@/constants/DateFormat';
import { useListTeamMembers } from '@/composables/queries/useUserQueries';
import { ITeamUser } from '@/api-v2/web/user';
import { createDate } from '@/utils/dateUtils';
import { Currency } from '@/constants/Currency';
import { isConstituentPortfolioFn } from '@/utils/portfolioTree';
import { SettingsModule } from '@/store/barrel';
import mitt from 'mitt';
import StandardFormDropdown from '@/components/standard-components/StandardFormDropdown.vue';
import InceptionDateCalendar from './InceptionDateCalendar.vue';
import IndexSearchBoxSelect from '@/components/index-search-box/IndexSearchBoxSelect.vue';
import { useAllPortfolios } from '@/composables/queries/useDataDiscovery';
import { notNull } from '@/utils/notnull';
import { PortfolioType } from '@/types/portfolio';
import { useRouteRef } from '@/composables/useRouter';
import useRouteChecks from '@/composables/useRouteChecks';

const { translate } = useTranslation();
const { changeFxConversionParam } = useFxConversion();

function formatUserName(user: ITeamUser): string {
  const { firstname, lastname, email } = user;
  return firstname === '' || lastname === '' ? email : `${firstname} ${lastname}`;
}

export default defineComponent({
  name: 'PortfolioStaticData',
  components: {
    CurrencyDropdownItems,
    InceptionDateCalendar,
    IndexSearchBoxSelect,
  },
  props: {
    portfolio: {
      type: Object as PropType<IPortfolioTree | null>,
      required: true,
    },
    portfolioTree: {
      type: Object as PropType<Omit<IPortfolioTreeSubportfolio, 'components'>>,
      required: true,
    },
    isVueQueryEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Is the given portfolio tree a master portfolio?
     */
    isMaster: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If true, disable all inputs in this component
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inheritedFrom: {
      type: String,
      required: false,
    },
    /**
     * Prevent the createdAt and updatedAt to show on create new and duplicate portfolio
     */
    showCreatedAt: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const isConstituentPortfolio = isConstituentPortfolioFn(computed(() => props.portfolio));
    const portfolioTreeData = useAllPortfolios();
    const eventBus = mitt<Record<'on-hidden', void>>();
    const dateDropdown = ref<InstanceType<typeof StandardFormDropdown> | null>(null);

    const assetClassTypes = Object.values(AssetClassConstants).map(
      translatorForProperty(ATTRIBUTE_DATABASE_NAME.AssetClass),
    );

    const route = useRouteRef();
    // TODO: confirm if this is a isOnEquityBasket logic or isEquityBasketPortfolio logic when the portfolio type is defined.
    const { isOnEquityBasket } = useRouteChecks(route);

    const dataTypeColors = SettingsModule.colors.productType || {};

    const benchmarkColor = `color:${dataTypeColors[DataTypes.BENCHMARK]};`;

    const factorTypes = Object.values(FactorConstants).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Factor));

    const regionTypes = Object.values(Region).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Region));

    const styleTypes = Object.values(StyleConstants).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.Style));

    const fxConversionTypes = Object.values(FxConversion);

    const teamMembers = useListTeamMembers({
      enabled: computed(() => props.isVueQueryEnabled && props.isMaster),
    });

    const portfolioTypes = [DataTypes.PORTFOLIO, DataTypes.BENCHMARK].map((o) => ({
      label: translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: o }),
      value: o === DataTypes.BENCHMARK,
    }));

    const dsbOptionsBenchmark: DSBOptions = {
      selectMultiple: false,
    };

    const dsbOptionsPortfolioIndex: DSBOptions = {
      selectMultiple: false,
    };

    const inheritedType = computed(
      () =>
        `${translate({ path: 'MODALS.STATIC_DATA_MODAL.INHERITED' })} ${translate({ path: 'MODALS.GENERAL.FROM' })} ${
          props.inheritedFrom
        } (${translate({
          path: 'GLOBAL.FX_CONVERSION',
          item: props.portfolioTree.fxType,
        })})`,
    );

    const description = computed({
      get() {
        if (!props.portfolioTree.description) return '';
        return props.portfolioTree.description;
      },
      set(newVal: string) {
        props.portfolioTree.description = newVal;
      },
    });

    const isBenchmark = computed({
      get() {
        return props.portfolio?.isBenchmark ?? false;
      },
      set(o: boolean) {
        if (!props.portfolio) return;
        props.portfolio.isBenchmark = o;
      },
    });

    /**
     * List of codes already used as a reference
     * Should be disabled in the DSB
     */
    const disabledReferenceCodes = computed(() =>
      (portfolioTreeData.data.value ?? []).map((x) => x.reference).filter(notNull),
    );

    const createdBy = computed(() => {
      if (!props.portfolio) {
        return;
      }

      if (props.portfolio.createdBy === 0) {
        return 'Premialab'; // Auto-generated sample portfolios may have createdBy === 0
      }

      const match = teamMembers.data.value?.get(props.portfolio.createdBy);
      if (!match) {
        return null;
      }

      return formatUserName(match);
    });

    const createdAt = computed(() => {
      if (!props.portfolio) {
        return null;
      }

      const date = createDate(props.portfolio.createdAt, {
        format: InputDateFormat.ISO8601,
        allowNonBusiness: true,
      });

      return date.toFormat(DateFormat.D_MMM_YYYY);
    });

    const updatedBy = computed(() => {
      if (!props.portfolio) {
        return;
      }

      const match = teamMembers.data.value?.get(props.portfolio.updatedBy);
      if (!match) {
        return null;
      }

      return formatUserName(match);
    });

    const updatedAt = computed(() => {
      if (!props.portfolio) {
        return null;
      }

      const date = createDate(props.portfolio.updatedAt, {
        format: InputDateFormat.ISO8601,
        allowNonBusiness: true,
      });

      return date.toFormat(DateFormat.D_MMM_YYYY);
    });

    const onClickCurrency = (toCurrency: Currency) => {
      if (!props.portfolio) return;
      props.portfolioTree.toCurrency = toCurrency;
      changeFxConversionParam(props.portfolio.portfolioTree, {
        toCurrency,
        fxType: props.portfolioTree.fxType,
      });
    };

    const onClickFxType = (fxType: FxConversion) => {
      if (!props.portfolio) return;
      props.portfolioTree.fxType = fxType;
      changeFxConversionParam(props.portfolio.portfolioTree, {
        toCurrency: props.portfolioTree.toCurrency,
        fxType,
      });
    };

    /**
     * Reset the calendar when it is hidden
     */
    const onHidden = () => {
      eventBus.emit('on-hidden');
    };

    /**
     * Allows for the dropdown to close properly
     */
    const closeDropdown = () => {
      if (dateDropdown.value) dateDropdown.value.hide();
    };

    /**
     * new custom inception date chosen from the calendar
     */
    const onNewCustomInceptionDate = (newDate: string | undefined) => {
      set(props.portfolioTree, 'customInceptionDate', newDate);
    };

    const removeCustomInceptionDate = () => {
      set(props.portfolioTree, 'customInceptionDate', undefined);
    };

    return {
      DataTypes,
      translate,
      assetClassTypes,
      factorTypes,
      regionTypes,
      styleTypes,
      fxConversionTypes,
      FxConversion,
      portfolioTypes,
      inheritedType,
      description,
      isBenchmark,
      onClickCurrency,
      onClickFxType,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      benchmarkColor,
      isConstituentPortfolio,
      disabledReferenceCodes,

      onHidden,
      eventBus,
      closeDropdown,
      dateDropdown,
      onNewCustomInceptionDate,
      removeCustomInceptionDate,

      dsbOptionsPortfolioIndex,
      dsbOptionsBenchmark,

      PortfolioType,
      isOnEquityBasket,
    };
  },
});
</script>
<style scoped>
.no-padding-left :deep(.form-row .col) {
  padding-left: 0;
}
</style>
