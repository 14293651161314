export enum SegmentType {
  ASSET_TYPE = 'Asset Type',
  CURRENCY = 'Currency',
  INSTRUMENT = 'Instrument',
  LONG_SHORT = 'L/S',
  PRODUCT_TYPE = 'Product Type',
  REGION = 'Region',
  SECTOR = 'Sector',
  STRATEGY = 'Strategy',
  PORTFOLIO = 'Portfolio',
}
