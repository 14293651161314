import { computed } from 'vue';
import { useRouteRef } from './useRouter';
import useRouteChecks from './useRouteChecks';
import usePortfolioTree from './usePortfolioTree';
import { isPortfolioTreeStrategy } from '@/types/IPortfolioTree';
import { isStrategy } from '@/utils/strategy';
import useEnv from './useEnv';
import { usePortfolioTreeDraft } from './queries/usePortfolioTreeData';
import { CalculateParamConstants } from '@/constants/CalculateParamConstants';

/**
 * The unique identifier of a portfolio or strategy under analysis
 * WARNING: Only use to get tree draft or when comparing with portfolioTreeOriginalIdentifier
 * transformToUppercase should only be true on the HSBC environment and strategy only pages
 */
export function useIndexUniqueIdentifier() {
  const route = useRouteRef();
  const { isHSBCEnvironment } = useEnv();
  const { isPortfolioPage } = useRouteChecks(route);

  return computed(() => {
    if (isHSBCEnvironment && !isPortfolioPage.value) {
      return route.value.params.indexUniqueIdentifier.toUpperCase();
    }
    return route.value.params.indexUniqueIdentifier;
  });
}

/**
 * @deprecated - should use `usePortfolioTreeDraftIdentifiers` instead
 *
 * The draft slug of a portfolio tree under analysis
 * Should be used when the endpoint expects to receive the draft portfolio
 * A feeder portfolio will have its linked portfolios expanded with all their children visible
 */
export function usePortfolioTreeDraftIdentifier() {
  const { masterPortfolioTree } = usePortfolioTree();

  return computed(() => masterPortfolioTree.value?.slug ?? null);
}

/**
 * The original slug of a portfolio tree under analysis
 * Should be used when the endpoint expects to receive the original portfolio
 * A feeder portfolio will NOT have its linked portfolios expanded with all their children NOT visible
 *
 * WARNING: Should be used in all PDF and EXCEL exports.
 * For PDFs: the original slug is supplied so that the sls-chromeless project will go to a portfolio
 * that exists and then it will generate a draft slug itself and use that to generate the PDF
 *
 * For Excels: the original slug is supplied so that the generated excel will be used for the S3 bucket.
 * The API *MUST* expand the portfolio tree on their end so that the data is correct.
 */
export function usePortfolioTreeOriginalIdentifier() {
  const { masterPortfolioTree } = usePortfolioTree();

  return computed(() => masterPortfolioTree.value?.originalSlug ?? null);
}

/**
 * The code of a strategy under analysis
 */
export function useStrategyIdentifier() {
  const { itemUnderAnalysis } = usePortfolioTree();

  return computed(() => {
    if (itemUnderAnalysis.value) {
      if (isPortfolioTreeStrategy(itemUnderAnalysis.value) && itemUnderAnalysis.value.strategy)
        return itemUnderAnalysis.value.strategy.code;

      if (isStrategy(itemUnderAnalysis.value)) return itemUnderAnalysis.value.code;
    }

    return null;
  });
}

/**
 * The draft slug of a portfolio tree under analysis
 * Should be used when the endpoint expects to receive the draft portfolio (should be used on MOST endpoints)
 * Only on a draft will a feeder portfolio have its linked portfolios expanded with all their children visible
 *
 * originalSlug is used in places when we need to compare the current masterPortfolioTree against the
 * current draft. This may be able to be removed at a later date when instances of masterPortfolioTree
 * are replaced with the `usePortfolioTreeDraft` composable.
 */
export function usePortfolioTreeDraftIdentifiers(draftParams?: {
  positionDate: string;
  rescalingPage: CalculateParamConstants;
}) {
  const indexUniqueIdentifier = useIndexUniqueIdentifier();
  const route = useRouteRef();
  const { isPortfolioPage } = useRouteChecks(route);
  const { data: draft } = usePortfolioTreeDraft(indexUniqueIdentifier, draftParams, {
    enabled: isPortfolioPage,
  });

  return {
    originalSlug: computed(() => draft.value?.originalSlug ?? null),
    draftSlug: computed(() => draft.value?.slug ?? null),
  };
}
