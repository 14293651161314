import { PortfolioModule } from '@/store/barrel';
import AnalyticsStore from '@/store/modules/AnalyticsStore';
import { IPortfolioTree, isPortfolioTreeStrategy, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { findTreeComponentByStrategyCode } from '@/utils/portfolioTree';
import { computed, ComputedRef } from 'vue';
import { isStrategy } from '@/utils/strategy';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';

/**
 * @deprecated - should be replaced with `usePortfolioTreeDraft`
 */
const masterPortfolioTree = computed((): IPortfolioTree | null => {
  return PortfolioModule.portfolioTreeUnderAnalysis;
});

const itemUnderAnalysis = computed(() => AnalyticsStore.itemUnderAnalysis);

const portfolioName = computed((): string | null => {
  if (PortfolioModule.portfolioTreeUnderAnalysis) {
    return PortfolioModule.portfolioTreeUnderAnalysis.portfolioTree.name;
  }
  return null;
});

const hasActiveCodes = computed((): boolean => PortfolioModule.hasActiveCodes);

/**
 * When a portfolio is marked as read-only, we must enable the read-only mode for the user.
 */
const isReadOnlyPortfolio = computed(() => masterPortfolioTree.value?.parameters?.isReadOnlyPortfolio ?? false);

/**
 * First try the current subportfolio for the given symbol.
 * if it's not found in the current subportfolio tree, then it goes to the current master portfolio tree
 * and searches that
 */
const findPortfolioTreeIdByCode = (symbol: string | undefined) => {
  if (!symbol || !masterPortfolioTree.value) return;

  const portfolioTreeToUse =
    itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)
      ? itemUnderAnalysis.value
      : masterPortfolioTree.value.portfolioTree;

  if (symbol !== portfolioName.value) {
    let component = findTreeComponentByStrategyCode(portfolioTreeToUse, symbol);
    if (!component && itemUnderAnalysis.value && isPortfolioTreeSubportfolio(itemUnderAnalysis.value)) {
      // if component was not found in the subporfolio under analysis, try the whole tree
      component = findTreeComponentByStrategyCode(masterPortfolioTree.value.portfolioTree, symbol);
    }
    if (!component) return;
    return component.portfolioTreeId;
  }
  return masterPortfolioTree.value.portfolioId;
};

/**
 * A portfolio will only have portfolio return hence the returnType will be null.
 * Only tracks (strategies) will have a returnType
 * The condition (isPortfolioTreeStrategy(item) && !item.strategy) needs to be skipped as it can't be clicked
 */
const returnType = computed(() => {
  const item = itemUnderAnalysis.value;
  if (!item) return null;
  if (item) {
    if (isStrategy(item)) {
      return item.returnType ?? ReturnTypeConstants.TOTAL_RETURN;
    }

    if (isPortfolioTreeStrategy(item) && item.strategy) {
      return item.strategy.returnType ?? ReturnTypeConstants.TOTAL_RETURN;
    }
  }
  return null;
});

export default function (): {
  masterPortfolioTree: ComputedRef<IPortfolioTree | null>;
  itemUnderAnalysis: ComputedRef<IItemUnderAnalysis | null>;
  portfolioName: ComputedRef<string | null>;
  findPortfolioTreeIdByCode(symbol: string | undefined): string | undefined;
  hasActiveCodes: ComputedRef<boolean>;
  returnType: ComputedRef<ReturnTypeConstants | null>;
  isReadOnlyPortfolio: ComputedRef<boolean>;
} {
  return {
    masterPortfolioTree,
    itemUnderAnalysis,
    portfolioName,
    findPortfolioTreeIdByCode,
    hasActiveCodes,
    returnType,
    isReadOnlyPortfolio,
  };
}
