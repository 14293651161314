import { computed, unref, watch } from 'vue';
import useUser from './useUser';
import { useLocalStorage, useClipboardItems, MaybeRef } from '@vueuse/core';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import simplexLogo from '../assets/faux-whitelabel/simplex-white.svg';
import qraftLogo from '../assets/faux-whitelabel/qraft.svg';
import wtwLogo from '../assets/faux-whitelabel/wtw.svg';
import { RouteName } from '@/constants/RouteName';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import useEnv from './useEnv';
import { UserPermission } from '@/constants/UserPermission';
import { useHasPermission } from './usePermission';
import usePortfolioTree from './usePortfolioTree';

type PlatformConfig = {
  companyName: string;
  variant: string;
  logo: string;
  logoStyle?: Record<string, string>;
  correlationBenchmark?: string;
  defaultPeriod?: PeriodAbbrevEnum;
  homePageRoute?: RouteName;
};

// TEAM IDs
// - CORE ENV
const SIMPLEX_PROD = 476;
const SIMPLEX_UAT = 125;
const QRAFT_PROD = 494;
const WTW_PROD = 500;
const WTW_UAT = 129;
const VER_PROD = 300;
const NISA_PROD = 344;
const BRIGHTWELL_PROD = 442;

// CONFIGS
const simplexConfig: PlatformConfig = {
  companyName: 'Simplex AM',
  variant: 'simplex',
  logo: simplexLogo,
  correlationBenchmark: 'NKYNTR',
  defaultPeriod: PeriodAbbrevEnum.LIVE,
  homePageRoute: RouteName.PORTFOLIO_CONSTRUCTION,
};

const qraftConfig: PlatformConfig = {
  companyName: 'Qraft',
  variant: 'qraft',
  logo: qraftLogo,
  logoStyle: { 'max-height': '25px' },
  correlationBenchmark: 'SPX',
  defaultPeriod: PeriodAbbrevEnum.LIVE,
};

const wtwConfig: PlatformConfig = {
  companyName: 'Willis Towers Watson',
  variant: 'wtw',
  logo: wtwLogo,
  logoStyle: { 'max-height': '25px' },
  correlationBenchmark: 'SPX',
  defaultPeriod: PeriodAbbrevEnum.LIVE,
};

const fauxWhitelabelConfigs: {
  [teamId: number]: PlatformConfig;
} = {
  [SIMPLEX_PROD]: simplexConfig,
  [SIMPLEX_UAT]: simplexConfig,
  [QRAFT_PROD]: qraftConfig,
  [WTW_UAT]: wtwConfig,
  [WTW_PROD]: wtwConfig,
};

export function useFeatureFlag() {
  const { isUserAdmin, user } = useUser();

  const { isSupported: isClipboardItemsSupported } = useClipboardItems();

  const {
    isCoreEnvironment,
    isHSBCEnvironment,
    isBofAEnvironment,
    isBarclaysEnvironment,
    isProduction,
    isActuallyProduction,
  } = useEnv();

  const isCoreProdTeam = (teamId: number) => {
    return (
      isCoreEnvironment &&
      user.value &&
      user.value.teamId === teamId &&
      [SIMPLEX_PROD, QRAFT_PROD, WTW_PROD, VER_PROD, NISA_PROD, BRIGHTWELL_PROD].includes(teamId)
    );
  };

  const isCoreUatTeam = (teamId: number) => {
    return isCoreEnvironment && user.value && user.value.teamId === teamId && [SIMPLEX_UAT, WTW_UAT].includes(teamId);
  };

  const fauxWhitelabelTeams = computed(() => {
    // CORE and PROD teams
    if (isCoreEnvironment && isProduction.value) {
      return [SIMPLEX_PROD, QRAFT_PROD, WTW_PROD];
    }
    // CORE and UAT teams
    if (isCoreEnvironment && !isProduction.value) {
      return [SIMPLEX_UAT, WTW_UAT];
    }
    return [];
  });

  // FLAGS
  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyShouldUseAdminBackDoor = useLocalStorage(LocalStorageEntities.SHOULD_USE_ADMIN_BACK_DOOR, false);
  /**
   * This boolean is designed to temporarily disable the limited functionality of the platform when an
   * admin is impersonating an account with limited permissions (originally faux whitelabel, now separated
   * into several types of permission: isReadOnlyPlatform, shouldFactorDecompositionBeVisible,
   * shouldPreventStrategyFactsheetAccess, canSwitchPortfolios, canSeeMyLab, canSeeInsights).
   */
  const shouldUseAdminBackDoor = useLocalStorage(
    LocalStorageEntities.SHOULD_USE_ADMIN_BACK_DOOR_V2,
    legacyShouldUseAdminBackDoor.value,
  );

  const { isReadOnlyPortfolio } = usePortfolioTree();

  const isReadOnlyPlatformTeam = useHasPermission(UserPermission.READ_ONLY_PLATFORM);

  /**
   * Flag to determine if the platform is in read-only mode.
   * If the user is accessing a read-only portfolio, then the platform is in read-only mode.
   * If the user is part of a read-only platform team, then the platform is in read-only mode.
   */
  const isReadOnlyPlatform = computed(
    () => isReadOnlyPortfolio.value || (isReadOnlyPlatformTeam.value && !shouldUseAdminBackDoor.value) || false,
  );

  /**
   * The Save Portfolio Button on the Portfolio Weight Toolbar should be visible when a portfolio is marked as read-only
   * It should not be visible when the user is part of a read-only platform team
   */
  const canSeePortfolioSaveButton = computed(
    () => isReadOnlyPortfolio.value || !(isReadOnlyPlatformTeam.value && !shouldUseAdminBackDoor.value),
  );

  const isFauxWhitelabel = computed(
    () => (user.value && fauxWhitelabelTeams.value.includes(user.value.teamId)) ?? false,
  );

  const isHSBCEnvironmentAdmin = computed(() => isHSBCEnvironment && isUserAdmin.value);

  const shouldConstituentRiskBeVisible = computed(() => isCoreEnvironment || isBofAEnvironment);

  const isStrategyPerformancePermissionEnabled = useHasPermission(UserPermission.STRATEGY_PERFORMANCE);

  const shouldPerformanceContributionBeVisible = computed(
    () => (isCoreEnvironment && isStrategyPerformancePermissionEnabled.value) || isBofAEnvironment,
  );

  const shouldExportButtonBeVisible = computed(() => isCoreEnvironment || isBarclaysEnvironment || isBofAEnvironment);

  const shouldClusteringAnalysisBeVisible = computed(() => isCoreEnvironment);

  const shouldFactorDecompositionBeVisible = computed(() => {
    if (!isCoreEnvironment || !user.value) return false;

    if (shouldUseAdminBackDoor.value) return true;

    return !isReadOnlyPlatformTeam.value;
  });

  /**
   * Negative variable because most of the time we assume we have Strategy Factsheet access
   */
  const shouldPreventStrategyFactsheetAccess = computed(() => {
    if (!user.value) return true;

    if (shouldUseAdminBackDoor.value) return false;

    return isReadOnlyPlatformTeam.value;
  });

  const shouldReturnBeVisible = computed(() => !isHSBCEnvironment);

  const fauxWhitelabelConfig = computed(() => {
    if (!user.value) return null;
    return fauxWhitelabelConfigs[user.value.teamId];
  });

  const hasHackyPortfolioMap = computed(() => {
    return (isCoreProdTeam(SIMPLEX_PROD) || isCoreUatTeam(SIMPLEX_UAT)) ?? false;
  });

  const shouldUseHomePageRoute = computed(
    () => isFauxWhitelabel.value && fauxWhitelabelConfig.value?.homePageRoute && !isHSBCEnvironment,
  );

  const shouldShowAdminOfficialTracks = computed(() => !isHSBCEnvironment);

  const homePageRoute = computed(() => {
    if (isFauxWhitelabel.value && fauxWhitelabelConfig.value?.homePageRoute) {
      return fauxWhitelabelConfig.value?.homePageRoute;
    }
    return RouteName.DATA;
  });

  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyUserWantsToUseStrategyMask = useLocalStorage(LocalStorageEntities.USE_STRATEGY_MASK_UPDATED, true);
  const userWantsToUseStrategyMask = useLocalStorage(
    LocalStorageEntities.USE_STRATEGY_MASK_UPDATED_V2,
    legacyUserWantsToUseStrategyMask.value,
  );

  const shouldShowHideBetaFeaturesButton = computed(() => isUserAdmin.value);

  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyShouldHideBetaFeatures = useLocalStorage(LocalStorageEntities.HIDE_BETA_FEATURES, !isUserAdmin.value);
  /**
   * Boolean designed for admins to toggle off experimental features during demos, etc
   *
   * For non-admins, the value doesn't really matter (it only applies if user is admin or has permission),
   * but we initialize it to true for them anyway
   */
  const shouldHideBetaFeatures = useLocalStorage(
    LocalStorageEntities.HIDE_BETA_FEATURES_V2,
    legacyShouldHideBetaFeatures.value,
  );

  /**
   * Create a flag with a default value of being on visible to admins on the Core environment when
   * shouldHideBetaFeatures is false
   */
  const createNewFeatureFlag = ({
    nonAdminPermission,
    supportedEnvs,
    requiredCondition,
    experimental = true,
  }: {
    /**
     * The default value of this computed is dependent on whether the user is an admin.
     * If the feature should also be made available to certain teams or other users with a given permission type,
     * feed the supplemental permissions here, i.e., it uses a logical OR: (isUserAdmin || nonAdminPermission).
     */
    nonAdminPermission?: MaybeRef<boolean>;
    /**
     * The default value of this computed will only be true if we are in the Core environment.
     * To make this true in other environments as well, supply that here, i.e., it uses a logical OR:
     * (isCoreEnvironment || supportedEnvs).
     */
    supportedEnvs?: MaybeRef<boolean>;
    /**
     * If the visibility of this feature is dependent on another condition (e.g., a toggle)
     * then supply that condition to `requiredCondition`. Then this feature will only be visible when the
     * permission values, environment values, and the additional condition are all true, i.e., it uses a logical AND:
     * (isUserAdmin && isCoreEnvironment && requiredCondition).
     */
    requiredCondition?: MaybeRef<boolean>;
    /**
     * Defaults to `true`. If experimental, this feature will only be visible when the admin has set
     * `shouldHideBetaFeatures` to false.
     */
    experimental?: boolean;
  } = {}) => {
    return computed(() => {
      const isAdminOrHasPermission = isUserAdmin.value || unref(nonAdminPermission);

      const isPlatformSupported = isCoreEnvironment || unref(supportedEnvs);

      const defaultValue = isAdminOrHasPermission && isPlatformSupported && unref(requiredCondition ?? true);

      if (experimental && isUserAdmin.value) return defaultValue && !shouldHideBetaFeatures.value;
      return defaultValue;
    });
  };

  // Some articles should only be visible on the core platform and not on whitelabels
  const canUserSeeKnowledgeBaseArticles = computed(() => isCoreEnvironment);

  const canUserExportGraphData = computed(() => isUserAdmin.value);

  const shouldShowNotionAsCategory = computed(() => !isProduction.value);

  const hasPortfolioConstructionAccess = computed(
    () =>
      (useHasPermission(UserPermission.PORTFOLIO).value && (isCoreEnvironment || isBarclaysEnvironment)) ||
      isHSBCEnvironmentAdmin.value,
  );

  const hasEquityBasketAccess = createNewFeatureFlag({
    // temporarily hide equity basket content from BofA as its not ready yet.
    // nonAdminPermission: useHasPermission(UserPermission.EQUITY_BASKET),
    // supportedEnvs: isBofAEnvironment,
  });

  /**
   * 24 July 2024, exposing this feature to all clients on Core only (until told otherwise)
   */
  const hasNewDataUniverseAccess = createNewFeatureFlag({
    nonAdminPermission: true,
    experimental: false,
  });

  const shouldShowHSBCDisclaimer = computed(() => isHSBCEnvironment);

  const canUserSeeMarketRegimes = createNewFeatureFlag({
    nonAdminPermission: true,
    experimental: false,
  });

  /** Tcorp START */
  const canSeeNewFactorDecompositionGraphs = createNewFeatureFlag();
  const canUserSeeMarketRegimesOnNewPages = createNewFeatureFlag();
  const canSeeNewFactorDecompMetrics = createNewFeatureFlag();
  const canSeeNewFactsheetRiskStats = createNewFeatureFlag();
  /** Tcorp END */

  const showPeriodEndDateTopHoldings = computed(() => isHSBCEnvironment);

  /**
   * Verifies ClipboardItem object support in the browser used for copying to clipboard.
   * Note: Firefox does not support ClipboardItem.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem/ClipboardItem
   */
  const isCopyToClipboardSupported = computed(() => {
    return isClipboardItemsSupported.value && !navigator.userAgent.includes('Firefox');
  });

  /**
   * VER requires seeing these history mode graphs for VaR and Exposure, but
   * we need to clean the data for the remaining clients before we can expose these pages on the PDF to them.
   */
  const canRiskPdfShowExposureVarHistoryGraphs = computed(() => isUserAdmin.value || isCoreProdTeam(VER_PROD));

  /**
   * Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
   */
  const legacyEnableTeamDefaultPeriodReturnIntervalSettings = useLocalStorage(
    LocalStorageEntities.SHOULD_USE_USER_DEFAULT_PERIOD_RETURN_INTERVAL,
    false,
  );
  const enableTeamDefaultPeriodReturnIntervalSettings = useLocalStorage(
    LocalStorageEntities.SHOULD_USE_USER_DEFAULT_PERIOD_RETURN_INTERVAL_V2,
    legacyEnableTeamDefaultPeriodReturnIntervalSettings.value,
  );

  const hasActiveReturnAccess = computed(() => isCoreEnvironment);
  const canSeeActiveReturnRebalancing = computed(() => !isProduction.value);
  const hasActiveReturnPdfAccess = computed(() => isUserAdmin.value);
  const shouldShowStrategyAsDefaultIndexOptionsTable = computed(() => isHSBCEnvironment || isBarclaysEnvironment);
  const shouldShowLighterBgForTables = computed(() => isHSBCEnvironment || isBarclaysEnvironment);

  const shouldDisplayEmulateEquityBasketButton = computed(
    () => isUserAdmin.value && !isActuallyProduction && isCoreEnvironment,
  );

  const shouldDisplayEmulateProductionButton = computed(
    () => isUserAdmin.value && !isActuallyProduction && isCoreEnvironment,
  );

  const getShouldDisplayDistributionGraphs = () => {
    // Will be removed. (refer to the comments of src/constants/LocalStorageEntities.ts)
    const legacyShouldShowDistributionGraphs = useLocalStorage(
      LocalStorageEntities.SHOW_DISTRIBUTION_GRAPHS,
      isUserAdmin.value || isCoreProdTeam(WTW_PROD),
    );

    const retval = useLocalStorage(
      LocalStorageEntities.SHOW_DISTRIBUTION_GRAPHS_V2,
      legacyShouldShowDistributionGraphs.value,
    );

    watch(
      [user, isUserAdmin],
      ([, newIsUserAdmin]) => {
        retval.value = newIsUserAdmin || isCoreProdTeam(WTW_PROD);
      },
      { immediate: true },
    );

    return retval;
  };

  /**
   * Special case for showing the user setting in the sidebar.
   * Only for all gated whitelabel platforms (all whitelabel envs except HSBC)
   */
  const shouldShowWhitelabelUserSettings = !isCoreEnvironment && !isHSBCEnvironment;

  const shouldShowPortfolioTabHSBC = computed(() => isHSBCEnvironmentAdmin.value && !isActuallyProduction);

  const canAccessPerformanceContributionParametersModal = createNewFeatureFlag({
    experimental: false,
  });

  /**
   * If this boolean is false, then the PortfolioQuickSearch will be disabled.
   * Instead of a dropdown, it will just show the name of the current portfolio.
   *
   * Also, the Folder icon in the ApplicationSidebar will be hidden.
   */
  const canSwitchPortfolios = computed(() => {
    if (!user.value) return false;

    if (shouldUseAdminBackDoor.value) return true;

    return !isReadOnlyPlatformTeam.value;
  });

  const canSeeMyLab = computed(() => {
    if (!user.value) return false;
    if (shouldUseAdminBackDoor.value) return true;

    return !isReadOnlyPlatformTeam.value;
  });

  const canSeeInsights = computed(() => {
    if (!user.value) return false;
    if (shouldUseAdminBackDoor.value) return true;

    return !isReadOnlyPlatformTeam.value;
  });

  /**
   * Flag for enable file downloads page.
   *
   * Currently only open to admins, NISA team (id = 344) and Brightwell team
   * (id = 442).
   *
   * Jira: WAA-5743 and WAA-8439
   */
  const enableFileDownloads = computed(
    () => isUserAdmin.value || isCoreProdTeam(NISA_PROD) || isCoreProdTeam(BRIGHTWELL_PROD),
  );

  /**
   * Index Value Display is a component used to display the index value in the top right corner of the page
   * It is currently only visible in the Barclays Environment on the Track Record Chart section title
   */
  const shouldShowIndexValueDisplay = computed(() => isBarclaysEnvironment);

  const canSeeNewFactsheetGraphs = createNewFeatureFlag();

  /**
   * Exposing this feature to admin users on Core, Barclays, and HSBC environments
   */
  const canAccessPortfolioSharingFeature = createNewFeatureFlag({
    supportedEnvs: isCoreEnvironment || isBarclaysEnvironment || isHSBCEnvironment,
    experimental: false,
  });

  /**
   * As of now, only admins can see the Portfolios in the DSB
   * It is still beta feature until calculations are confirmed
   */
  const canSeePortfoliosOnDSB = createNewFeatureFlag();
  const isRegressionEnabled = useHasPermission(UserPermission.REGRESSION);
  const shouldRegressionGlossaryBeVisible = computed(() => isRegressionEnabled.value || isBofAEnvironment);
  const canSeeRegressionGlossary = createNewFeatureFlag({
    supportedEnvs: isCoreEnvironment || isBofAEnvironment,
    nonAdminPermission: shouldRegressionGlossaryBeVisible,
    experimental: false,
  });

  const isPcaEnabled = useHasPermission(UserPermission.PCA);
  const shouldPcaGlossaryBeAvailable = computed(() => isPcaEnabled.value || isBofAEnvironment);
  const canSeePcaGlossary = createNewFeatureFlag({
    supportedEnvs: isCoreEnvironment || isBofAEnvironment,
    nonAdminPermission: shouldPcaGlossaryBeAvailable,
    experimental: false,
  });

  const isConstituentEnabled = useHasPermission(UserPermission.CONSTITUENT);
  const shouldConstituentGlossaryBeVisible = computed(() => isConstituentEnabled.value || isBofAEnvironment);
  const canSeeConstituentGlossary = createNewFeatureFlag({
    supportedEnvs: isCoreEnvironment || isBofAEnvironment,
    nonAdminPermission: shouldConstituentGlossaryBeVisible,
    experimental: false,
  });

  const shouldShowUnknownTermsGlossary = createNewFeatureFlag({ nonAdminPermission: true, experimental: false });

  const canSeePremialabDisclaimer = createNewFeatureFlag({
    supportedEnvs: isCoreEnvironment || isBofAEnvironment,
    nonAdminPermission: true,
    experimental: false,
  });

  const canSeeDisclaimerTitle = createNewFeatureFlag({
    supportedEnvs: isHSBCEnvironment,
    requiredCondition: !isCoreEnvironment,
    nonAdminPermission: true,
    experimental: false,
  });

  return {
    enableFileDownloads,
    isFauxWhitelabel,
    canSwitchPortfolios,
    fauxWhitelabelConfig,
    isReadOnlyPlatform,
    shouldPreventStrategyFactsheetAccess,
    hasHackyPortfolioMap,
    homePageRoute,
    canSeeMyLab,
    canSeeInsights,
    userWantsToUseStrategyMask,
    canUserSeeKnowledgeBaseArticles,
    canUserExportGraphData,
    hasActiveReturnAccess,
    shouldConstituentRiskBeVisible,
    shouldPerformanceContributionBeVisible,
    shouldClusteringAnalysisBeVisible,
    shouldFactorDecompositionBeVisible,
    shouldExportButtonBeVisible,
    shouldUseHomePageRoute,
    hasPortfolioConstructionAccess,
    shouldShowHSBCDisclaimer,
    canRiskPdfShowHistoryGraphs: false,
    canRiskPdfShowExposureVarHistoryGraphs,
    canUserSeeMarketRegimes,
    isCopyToClipboardSupported,
    shouldReturnBeVisible,
    shouldShowAdminOfficialTracks,
    enableTeamDefaultPeriodReturnIntervalSettings,
    canSeeActiveReturnRebalancing,
    shouldShowNotionAsCategory,
    hasActiveReturnPdfAccess,
    shouldShowStrategyAsDefaultIndexOptionsTable,
    shouldShowLighterBgForTables,
    shouldDisplayEmulateEquityBasketButton,
    shouldDisplayEmulateProductionButton,
    hasEquityBasketAccess,
    showPeriodEndDateTopHoldings,
    shouldShowWhitelabelUserSettings,
    shouldShowPortfolioTabHSBC,
    shouldUseAdminBackDoor,
    getShouldDisplayDistributionGraphs,
    shouldShowIndexValueDisplay,
    hasNewDataUniverseAccess,
    shouldShowHideBetaFeaturesButton,
    shouldHideBetaFeatures,
    canSeeNewFactsheetGraphs,
    canAccessPortfolioSharingFeature,
    canSeePortfolioSaveButton,
    canSeePortfoliosOnDSB,
    canSeeNewFactorDecompositionGraphs,
    canUserSeeMarketRegimesOnNewPages,
    canSeeNewFactorDecompMetrics,
    canSeeNewFactsheetRiskStats,
    canSeeRegressionGlossary,
    canSeePcaGlossary,
    canSeeConstituentGlossary,
    shouldShowUnknownTermsGlossary,
    canSeePremialabDisclaimer,
    canSeeDisclaimerTitle,
    canAccessPerformanceContributionParametersModal,
  };
}
