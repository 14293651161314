<template>
  <VueTypeaheadBootstrap
    v-bind="{
      ...$attrs,
      ...$props,
      query: internalQuery,
      data: unselectedDataToSearch,
      inputClass: [
        ...inputClass,
        {
          'is-invalid': state === false,
          'is-valid': state === true,
        },
      ],
      disableSort: true,
    }"
    class="flex-grow-1"
    @input="onInputChange"
    @hit="onHit"
  >
    <template #suggestion="{ data }">
      <slot
        name="suggestion"
        :data="data"
      />
    </template>
    <template
      v-if="feedback"
      #formFeedback
    >
      <span
        :class="{
          'valid-feedback': state === true,
          'invalid-feedback': state === false,
          'd-block': state !== undefined,
        }"
      >
        <!-- apply .d-block when there is validation to force Bootstrap to show it -->
        {{ feedback }}
      </span>
    </template>
  </VueTypeaheadBootstrap>
</template>
<script lang="ts">
// @ts-expect-error imports don't work for some reason
import VueTypeaheadBootstrap from '@/vendor/vue-typeahead-bootstrap/VueTypeaheadBootstrap.vue';
import useTranslation from '@/composables/useTranslation';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';

export default defineComponent({
  name: 'TypeaheadSearchBox',
  components: {
    VueTypeaheadBootstrap,
  },
  props: {
    keyProperty: {
      type: String,
      required: true,
    },
    dataToSearch: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<unknown[]>,
      required: false,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        const { translate } = useTranslation();
        return translate({ path: 'GLOBAL.PLACEHOLDERS.KEYWORD_SEARCH' });
      },
    },
    showOnFocus: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputClass: {
      type: Array,
      required: false,
      default: () => [],
    },
    state: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    feedback: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * When true, the selected item will be added to the internalSelected and selectedItems array
     * If item is added to the above arrays, it will not be shown in the search list again
     * When the item needs to be always searchable, set this prop to false
     */
    addToSelectedItems: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:query', 'onHit'],
  setup(props, { emit }) {
    const internalSelected = ref<unknown[]>([]);
    const foundNoResults = ref(false);
    const internalQuery = ref('');

    const updateQuery = () => {
      emit('update:query', internalQuery.value);
    };

    watch(
      () => props.query,
      (newVal) => {
        internalQuery.value = newVal;
      },
    );

    /**
     * Filter the dataToSearch array constantly to return only the items that are not already selected
     */
    const unselectedDataToSearch = computed(() => {
      return props.dataToSearch.filter(
        (item) => !selectedItemValues.value.includes((item as { [key: string]: unknown })[props.keyProperty]),
      );
    });

    /**
     * Updates the list of selected items when there is a match between the selectedItems array and the dataToSearch
     */
    const selectedItemValues = computed((): unknown[] => {
      if (
        internalSelected.value &&
        internalSelected.value.length > 0 &&
        (internalSelected.value[0] as { [key: string]: unknown })[props.keyProperty] !== undefined
      ) {
        return internalSelected.value.map((item) => (item as { [key: string]: unknown })[props.keyProperty]);
      }
      return [];
    });

    /**
     * Watch for changes in the selectedItems prop and update the internalSelected
     */
    watch(
      () => props.selectedItems,
      (newVal) => {
        internalSelected.value = newVal;
      },
      { deep: true, immediate: true },
    );

    /**
     * When searched item is selected, add it to the internalSelected array, emit the onHit event to the component
     * that uses this component, and updates the query to empty string for next search after finish processing
     *
     * @param item found item in the search list
     */
    const onHit = (item: unknown) => {
      if (props.addToSelectedItems) {
        toggleItem(item);
      }
      emit('onHit', item);

      nextTick(() => {
        internalQuery.value = '';
        updateQuery();
      });
    };

    const onInputChange = (text: string) => {
      foundNoResults.value = false;

      internalQuery.value = text;
      updateQuery();
    };

    /**
     * Add the item into the internalSelected array if item is not already selected
     * Remove the item from the array if item is already selected
     *
     * @param item item to be added/removed
     */
    const toggleItem = (item: unknown) => {
      if (internalSelected.value) {
        const index = internalSelected.value.indexOf(item);

        if (index < 0) {
          internalSelected.value.push(item);
        } else {
          internalSelected.value.splice(index, 1);
        }
      }

      internalQuery.value = '';
    };

    return {
      internalQuery,
      unselectedDataToSearch,
      onInputChange,
      onHit,
    };
  },
});
</script>
