import { createGlobalState } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useGetRegimeSets } from './queries/useRegimeAnalysis';

/**
 * This global state is used to track the current blmView id and
 * whether or not blmViw is active.
 */
const useState = createGlobalState(() => {
  const storeRegimeId = ref<string | undefined>();
  const isRegimeAnalysisActive = ref(false);
  const hoveredThresholdName = ref<string | undefined>();

  return {
    storeRegimeId,
    isRegimeAnalysisActive,
    hoveredThresholdName,
  };
});

/**
 * This composable is used to track the current regime and its id,
 * the list of regimes, and whether or not Regime Analysis mode is active.
 */
export default function useRegimeAnalysisState() {
  const state = useState();
  const query = useGetRegimeSets();

  /**
   * if isRegimeAnalysisActive is false, return null. There is no storeRegime if Regime Analysis mode is not active.
   */
  const storeRegime = computed(() => {
    if (!state.isRegimeAnalysisActive.value) return null;

    return query.data.value?.find((view) => view.attributeId === state.storeRegimeId.value) ?? null;
  });

  return {
    ...state,
    query,
    storeRegime,
  };
}
