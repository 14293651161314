import { portfolioPages, RouteName, navPages, strategyPages } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { IUserInfoResponse } from '@/api-v2/web/user';
import {
  PORTFOLIO_CONSTRUCTION_ACTIVE_RETURN,
  PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER,
  PORTFOLIO_CONSTRUCTION_PERF_ATTRIB,
  PORTFOLIO_CONSTRUCTION_SIMULATION,
} from '@/types/analytics/PortfolioConstruction';
import { computed, Ref } from 'vue';
import { Route } from 'vue-router';
import { PerformancePageType } from '@/constants/PerformancePageType';
import { FACTOR_DECOMPOSITION_ROLLING } from '@/types/analytics/FactorDecomposition';

export type AnalyticsRoute =
  | RouteName.PORTFOLIO_CONSTRUCTION
  | RouteName.CLUSTERING_ANALYSIS
  | RouteName.FACTOR_DECOMPOSITION_PORTFOLIO
  | RouteName.FACTOR_DECOMPOSITION_STRATEGY
  | RouteName.CONSTITUENT_RISK
  | RouteName.EQUITY_BASKET;

export const isAnalyticsRoute = (route: string): route is AnalyticsRoute => {
  return [
    RouteName.ANALYTICS,
    RouteName.PORTFOLIO_CONSTRUCTION,
    RouteName.CLUSTERING_ANALYSIS,
    RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
    RouteName.FACTOR_DECOMPOSITION_STRATEGY,
    RouteName.CONSTITUENT_RISK,
    RouteName.EQUITY_BASKET,
  ].includes(route as RouteName);
};

const routeToPermission = {
  [RouteName.PORTFOLIO_CONSTRUCTION]: UserPermission.PORTFOLIO,
  //  TODO: Add permission for Equity Basket
  [RouteName.EQUITY_BASKET]: UserPermission.EQUITY_BASKET,
  [RouteName.CLUSTERING_ANALYSIS]: UserPermission.PCA,
  [RouteName.FACTOR_DECOMPOSITION_PORTFOLIO]: UserPermission.REGRESSION,
  [RouteName.FACTOR_DECOMPOSITION_STRATEGY]: UserPermission.REGRESSION,
  [RouteName.CONSTITUENT_RISK]: UserPermission.CONSTITUENT,
};

/**
 * If `testRoute` is supplied and the user has permission to that route, this function will return `testRoute`
 *
 * If `testRoute` is not supplied, then iterate through a user's permissions and return the RouteName of the first
 * available analysis step to which the user has permission
 */
export const hasAnalyticsPermission = ({
  user,
  testRoute,
  currentRoute,
}: {
  user: IUserInfoResponse;
  testRoute?: AnalyticsRoute;
  currentRoute?: Route;
}): AnalyticsRoute | undefined => {
  if (testRoute && user.permission.includes(routeToPermission[testRoute])) {
    return testRoute;
  }

  if (user.permission.includes(UserPermission.PORTFOLIO)) {
    return RouteName.PORTFOLIO_CONSTRUCTION;
  }
  if (user.permission.includes(UserPermission.EQUITY_BASKET)) {
    return RouteName.EQUITY_BASKET;
  }
  if (user.permission.includes(UserPermission.PCA)) {
    return RouteName.CLUSTERING_ANALYSIS;
  }
  if (user.permission.includes(UserPermission.REGRESSION)) {
    // handle Strategy cases first
    if (testRoute === RouteName.FACTOR_DECOMPOSITION_STRATEGY) {
      return RouteName.FACTOR_DECOMPOSITION_STRATEGY;
    }
    if (currentRoute && currentRoute.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY) {
      return RouteName.FACTOR_DECOMPOSITION_STRATEGY;
    }

    // otherwise is Portfolio case
    return RouteName.FACTOR_DECOMPOSITION_PORTFOLIO;
  }
  if (user.permission.includes(UserPermission.CONSTITUENT)) {
    return RouteName.CONSTITUENT_RISK;
  }
};

export default function (route: Ref<Route>) {
  const isPortfolioTreeUrl = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION ||
      route.value.name === RouteName.EQUITY_BASKET ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET_PDF,
  );

  const onFactsheetPage = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET_PDF ||
      route.value.name === RouteName.STRATEGY_FACTSHEET ||
      route.value.name === RouteName.STRATEGY_FACTSHEET_PDF,
  );

  const isPortfolioConstruction = computed(() => route.value.name === RouteName.PORTFOLIO_CONSTRUCTION);

  const isPortfolioPage = computed(
    () => route.value.name !== undefined && portfolioPages.has(route.value.name as RouteName),
  );

  const isStrategyPage = computed(
    () => route.value.name !== undefined && strategyPages.has(route.value.name as RouteName),
  );

  const isNavPages = computed(() => route.value.name !== undefined && navPages.has(route.value.name as RouteName));

  const isOnFactorDecomposition = computed(
    () =>
      route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY,
  );

  const isOnPortfolioFactorDecomposition = computed(
    () => route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
  );

  const isOnFactorDecompositionRolling = computed(
    () =>
      (route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
        route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY) &&
      route.value.params.substep === FACTOR_DECOMPOSITION_ROLLING.path,
  );

  const isOnPca = computed(() => route.value.name === RouteName.CLUSTERING_ANALYSIS);

  const isOnSimulation = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_SIMULATION.path,
  );
  const isOnEfficientFrontier = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER.path,
  );

  const isOnActiveReturn = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_ACTIVE_RETURN.path,
  );

  const isOnPerformanceContribution = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_PERF_ATTRIB.path,
  );

  const isOnEquityBasket = computed(() => route.value.name === RouteName.EQUITY_BASKET);

  const isOnConstituentRisk = computed(() => route.value.name === RouteName.CONSTITUENT_RISK);

  const isOnReconciliation = computed(
    () =>
      route.value.name === RouteName.PERFORMANCE_ATTRIBUTION &&
      route.value.params.substep === PerformancePageType.RECONCILIATION,
  );

  return {
    isPortfolioTreeUrl,
    onFactsheetPage,
    isPortfolioConstruction,
    isOnFactorDecomposition,
    isOnPortfolioFactorDecomposition,
    isOnFactorDecompositionRolling,
    isOnPca,
    isOnActiveReturn,
    isOnPerformanceContribution,
    isPortfolioPage,
    isOnEquityBasket,
    isOnSimulation,
    isOnEfficientFrontier,
    isOnConstituentRisk,
    isOnReconciliation,
    isNavPages,
    isStrategyPage,
  };
}
