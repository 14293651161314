import {
  getDataUniverse,
  getPortfolioSummary,
  PortfolioItemResponseDTO,
  PortfolioSummaryResponseDTO,
  StrategyItemResponseDTO,
} from '@/api-v2/web/discover';
import { DataTypes, normalizePortfolioType, normalizeType } from '@/types/setting';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { formatStrategyName } from '@/utils/strategy';
import { useQuery } from '@tanstack/vue-query';

const keys = {
  /**
   * Note: keep this in sync with `discoveryAllKey()` in {@link file://./useStrategies.ts} and
   * {@link file://./usePortfolioTreeData.ts}.
   */
  all: () => [{ scope: 'discover' }] as const,
  portfolioSummary: () => [{ ...keys.all()[0], entity: 'portfolio-summary' }],
};

/**
 * Get all strategies.
 */
export function useAllStrategies(options: VQQueryOptions<StrategyItemResponseDTO[]> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    select(universe) {
      return (universe.strategy ?? []).map((s) => {
        s.shortName = formatStrategyName(s);
        return s;
      });
    },
    ...options,
  });
}

/**
 * Get a map of strategy id -> strategy
 */
export function useAllStrategiesById(options: VQQueryOptions<Map<string, StrategyItemResponseDTO>> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    select(universe) {
      return new Map(
        (universe.strategy ?? []).map((strategy) => {
          strategy.shortName = formatStrategyName(strategy);
          return [strategy.id, strategy];
        }),
      );
    },
    ...options,
  });
}

/**
 * Get a map of strategy code -> strategy
 */
export function useAllStrategiesByCode(options: VQQueryOptions<Map<string, StrategyItemResponseDTO>> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    select(universe) {
      return new Map(
        (universe.strategy ?? []).map((strategy) => {
          strategy.shortName = formatStrategyName(strategy);
          return [strategy.code, strategy];
        }),
      );
    },
    ...options,
  });
}

/**
 * Get an array of strategy of given type.
 *
 * Note that the type parameter is not a ref/reactive. Because we are
 * implementing the filter logic in `select` and reactivity might not work with
 * it. So we are playing safe here.
 *
 * This also exclude some DataTypes which are not queried by the same API.
 */
export function useStrategyByType(
  type: Exclude<DataTypes, DataTypes.SIGNAL | DataTypes.PORTFOLIO>,
  options: VQQueryOptions<StrategyItemResponseDTO[]> = {},
) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      let result = (universe.strategy ?? []).filter((s) => normalizeType(s) === type);

      switch (type) {
        case DataTypes.PURE_FACTOR: {
          result = result.map((s) => ({
            ...s,
            shortName: formatStrategyName(s),
          }));
          break;
        }
      }

      return result.sort((a, b) => a.shortName.localeCompare(b.shortName));
    },
  });
}

/**
 * Get all portfolios.
 * For a map of portfolios, use {@link useAllPortfoliosBySlug} instead
 */
export function useAllPortfolios(options: VQQueryOptions = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return [...(universe.portfolio ?? [])].sort((a, b) => a.name.localeCompare(b.name));
    },
  });
}

/**
 * Get a map of portfolio id -> portfolio
 */
export function useAllPortfoliosById(options: VQQueryOptions<Map<string, PortfolioItemResponseDTO>> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return new Map((universe.portfolio ?? []).map((portfolio) => [portfolio.portfolioId, portfolio]));
    },
  });
}

/**
 * Get a map of portfolio slug -> portfolio
 */
export function useAllPortfoliosBySlug(options: VQQueryOptions<Map<string, PortfolioItemResponseDTO>> = {}) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return new Map((universe.portfolio ?? []).map((portfolio) => [portfolio.slug, portfolio]));
    },
  });
}

/**
 * Get an array of portfolios of given type.
 *
 * Note that the type parameter is not a ref/reactive. Because we are
 * implementing the filter logic in `select` and reactivity might not work with
 * it. So we are playing safe here.
 */
export function usePortfolioByType(
  type: DataTypes.PORTFOLIO | DataTypes.BENCHMARK,
  options: VQQueryOptions<PortfolioItemResponseDTO[]> = {},
) {
  return useQuery(keys.all(), () => getDataUniverse(), {
    ...options,
    select(universe) {
      return (universe.portfolio ?? [])
        .filter((o) => normalizePortfolioType(o) === type)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  });
}

/**
 * Gets a summary of portfolios which strategies can be added to.
 */
export function usePortfolioSummary(options: VQQueryOptions<PortfolioSummaryResponseDTO[]> = {}) {
  return useQuery(keys.portfolioSummary(), () => getPortfolioSummary(), options);
}
