<template>
  <b-container
    class="position-relative w-100 h-100 px-3 py-3 user-select-none"
    style="min-height: 100vh"
  >
    <b-row
      ref="headerHtmlElement"
      class="py-3 bg-white sticky-top"
      :style="{ top: stickyHeaderTopPosition }"
      no-gutters
    >
      <b-col class="d-flex align-items-center">
        <template v-if="category === knowledgeBaseCategory.HELP_CENTER">
          <h1 class="m-0">
            {{ translate({ path: 'KNOWLEDGE_BASE.HOW_CAN_WE_HELP' }) }}
          </h1>
        </template>
        <template v-else>
          <b-button
            variant="blind-primary"
            @click="navigateTo(knowledgeBaseCategory.HELP_CENTER)"
          >
            <icon
              icon="angle-left"
              size="2x"
            />
          </b-button>
          <h2 class="m-0 ml-3">
            {{ article?.title ?? translate({ path: 'KNOWLEDGE_BASE.CATEGORIES', item: category }) }}
          </h2>
        </template>
        <template v-if="shouldShowSidebarControls">
          <b-button
            variant="blind-primary"
            class="p-1 ml-2"
            :href="href"
          >
            <icon
              icon="arrow-up-right-from-square"
              fixed-width
            />
          </b-button>
          <b-button
            class="ml-auto p-1"
            variant="blind-secondary"
            @click="toggleSidebar"
          >
            <icon
              icon="xmark"
              size="2x"
              fixed-width
            />
          </b-button>
        </template>
      </b-col>
    </b-row>
    <component
      :is="componentOptions[category]"
      class="p-0"
    />
    <KnowledgeBaseMediaViewerModal />
  </b-container>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import KnowledgeBaseLandingPage from './KnowledgeBaseLandingPage.vue';
import WhitepaperPage from '../resources/whitepaper.vue';
import ApiDocs from '@/views/resources/api-docs.vue';
import GlossaryPage from '@/views/resources/glossary.vue';
import useTranslation from '@/composables/useTranslation';
import { KnowledgeBaseCategory } from '@/types/KnowledgeBase';
import KnowledgeBaseArticle from './KnowledgeBaseArticle.vue';
import KnowledgeBaseMediaViewerModal from './KnowledgeBaseMediaViewerModal.vue';
import { useKnowledgeBase } from '@/composables/useKnowledgeBase';
import { ApplicationNavbarHeight } from '@/constants/ApplicationNavbarHeight';
import { KnowledgeBaseMode } from '@/types/KnowledgeBase';
import { useTrackPageView } from '@/composables/useAppMetrics';
import { useRouteRef } from '@/composables/useRouter';
import useEnv from '@/composables/useEnv';

export const headerPadding = 32; // px

export default defineComponent({
  name: 'KnowledgeBase',
  components: {
    KnowledgeBaseMediaViewerModal,
  },
  setup() {
    const { translate } = useTranslation();

    const { mode, href, article, category, headerHtmlElement, navigateTo, toggleSidebar } = useKnowledgeBase();

    const route = useRouteRef();

    watch(
      route,
      (newVal) => {
        const { hash } = newVal;

        const potentialCategory = (hash.slice(1) as KnowledgeBaseCategory) ?? '';
        // if it matches a category, then set it
        if (Object.values(KnowledgeBaseCategory).includes(potentialCategory)) {
          category.value = hash.slice(1) as KnowledgeBaseCategory;
        }
      },
      { immediate: true },
    );

    // segment tracking
    const pageName = mode.value === KnowledgeBaseMode.PAGE ? 'Knowledge Base Page' : 'Knowledge Base Sidebar';
    const subpageName = 'Knowledge Base Page View Duration';
    useTrackPageView({ pageName, subpageName });

    /**
     * componentOptions is a map of KnowledgeBaseCategory to the component to render
     */
    const componentOptions: { [category in KnowledgeBaseCategory]: unknown } = {
      [KnowledgeBaseCategory.HELP_CENTER]: KnowledgeBaseLandingPage,
      [KnowledgeBaseCategory.GETTING_STARTED]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.PURE_FACTORS]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.MY_LAB]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.PORTFOLIO_CONSTRUCTION]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.PRINCIPAL_COMPONENT]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.FACTOR_DECOMPOSITION]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.CONSTITUENT_RISK]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.FACTSHEET]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.CASE_STUDY]: KnowledgeBaseArticle,
      [KnowledgeBaseCategory.WHITEPAPER]: WhitepaperPage,
      [KnowledgeBaseCategory.API_DOCUMENTATION]: ApiDocs,
      [KnowledgeBaseCategory.GLOSSARY]: GlossaryPage,
      [KnowledgeBaseCategory.NOTION]: KnowledgeBaseArticle,
    };

    const { isBofAEnvironment } = useEnv();

    const stickyHeaderTopPosition = computed(() => {
      if (isBofAEnvironment) {
        return '0px';
      }
      return mode.value === KnowledgeBaseMode.PAGE ? `${ApplicationNavbarHeight}px` : '0px';
    });

    const shouldShowSidebarControls = computed(() => mode.value === KnowledgeBaseMode.SIDEBAR);

    return {
      translate,

      href,
      article,
      category,
      headerHtmlElement,
      navigateTo,
      toggleSidebar,

      stickyHeaderTopPosition,
      shouldShowSidebarControls,

      componentOptions,
      knowledgeBaseCategory: KnowledgeBaseCategory,
    };
  },
});
</script>
