<template>
  <div>
    <div
      class="list-group-item-heading"
      :class="{
        'text-ellipsis': isPdf || !linkShouldWrap || useEllipsis,
      }"
    >
      <b-link
        :to="getStrategyFactsheetLocation(strategy.code)"
        :disabled="shouldPreventStrategyFactsheetAccess"
        rel="noopener noreferrer"
        @click.stop
      >
        {{ title }}
        <span
          v-if="!title"
          class="text-light-gray"
        >
          {{ translate({ path: 'GLOBAL.NO_NAME' }) }}
        </span>
      </b-link>
      <em
        v-if="showSuspended && strategy.isSuspended"
        class="text-light-gray"
      >
        - not updated
      </em>
    </div>
    <div
      v-if="shouldShowSecondLine"
      class="text-wrap-xs"
      :class="{
        'text-ellipsis': isPdf || useEllipsis,
      }"
    >
      {{ subtext }}
      <span v-if="shouldShowCurrency">
        <!-- don't show pipe if subtext is blank (like for Portfolio Composition) -->
        <span v-if="subtext">| </span><span :style="currencyColor">{{ additionalSubtext }}</span>
      </span>
      <span v-if="strategyType">
        <!-- don't show pipe if there's no preceding text -->
        <span v-if="shouldShowCurrency || subtext"> | </span>
        <span :style="strategyColor">{{ translate({ path: 'GLOBAL.STRATEGY_TYPE_NAME', item: strategyType }) }}</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { IStrategy } from '@/types/strategy';
import { normalizeType, DataTypes } from '@/types/setting';
import { getSubtext, getAdditionalSubtext } from '@/utils/strategy';
import useTranslation from '@/composables/useTranslation';
import useFxConversion from '@/composables/useFxConversion';
import { FxConversion } from '@/constants/FxConversion';
import { StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { SettingsModule } from '@/store/barrel';
import { useResolveFactsheetRoutes } from '@/composables/useResolveFactsheetRoutes';
import { useIsPdf } from '@/composables/usePdf';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import useStrategyMask from '@/composables/useStrategyMask';

const { translate } = useTranslation();
const { toCurrency } = useFxConversion();

export default defineComponent({
  name: 'StrategySubtext',
  props: {
    strategy: {
      type: Object as PropType<IStrategy | StrategyItemResponseDTO>,
      required: true,
    },
    showSuspended: {
      type: Boolean,
      default: false,
    },
    regressorFxType: {
      type: String as PropType<FxConversion>,
      required: false,
    },
    shouldUseFxCurrency: {
      type: Boolean,
      required: false,
      default: true,
    },
    linkShouldWrap: {
      type: Boolean,
      required: false,
      default: true,
    },
    useEllipsis: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowSecondLine: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * For Portfolio Composition, we already show the Asset Class and Factor columns,
     * so there's no need to include this information in the subtext
     */
    isPortfolioCompositionSection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const dataTypeColors = SettingsModule.colors.productType || {};

    const strategyType = computed(() => {
      const normalizedType = normalizeType(props.strategy);
      return normalizedType;
    });

    const { shouldPreventStrategyFactsheetAccess } = useFeatureFlag();
    const { getStrategyMask } = useStrategyMask();

    const title = computed(() => {
      return getStrategyMask(props.strategy);
    });

    const subtext = computed(() => {
      return getSubtext(props.strategy, props.isPortfolioCompositionSection);
    });

    const additionalSubtext = computed(() => {
      return getAdditionalSubtext({
        strategy: props.strategy,
        regressorFxType: props.regressorFxType,
        shouldUseGlobalFxCurrency: props.shouldUseFxCurrency,
      });
    });

    /**
     * Each strategy type will have their own individual colours
     */
    const strategyColor = computed(() => {
      if (!strategyType.value) return '';
      return `color:${dataTypeColors[strategyType.value]};`;
    });

    /**
     * Only strategy of type benchmark and strategy, require to see the currency and return types/categories
     */
    const shouldShowCurrency = computed(() => {
      return !!props.strategy.currency;
    });

    /**
     * On the regression page, when the currency is shown, if a Fx Conversion is applied to the regressor
     * we want to change the color
     */
    const currencyColor = computed(() => {
      if (
        !props.regressorFxType ||
        props.regressorFxType === FxConversion.NOT_CONVERTED ||
        props.strategy.currency === toCurrency.value
      )
        return '';
      return `color:${dataTypeColors[DataTypes.THEMATIC]};`;
    });

    const { getStrategyFactsheetLocation } = useResolveFactsheetRoutes();

    const isPdf = useIsPdf();

    return {
      title,
      strategyColor,
      subtext,
      additionalSubtext,
      shouldShowCurrency,
      strategyType,
      currencyColor,
      getStrategyFactsheetLocation,
      translate,
      isPdf,
      shouldPreventStrategyFactsheetAccess,
    };
  },
});
</script>

<style scoped>
@media (min-width: 992px) {
  .text-wrap-xs {
    text-overflow: wrap !important;
  }
}
</style>
