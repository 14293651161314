<template>
  <b-dd-form @click.stop>
    <div ref="calendar">
      <!-- No 'min' is set since a portfolio may be invested at any time by a user  -->
      <b-calendar
        v-model="selectedDate"
        :date-disabled-fn="disableWeekends"
        :max="previousBusinessDay"
        selected-variant="info"
        no-highlight-today
        hide-header
        class="mb-2"
      />
      <b-form
        inline
        class="d-flex justify-content-center"
      >
        <b-button @click="closeDropdown">
          {{ translate({ path: 'GLOBAL.CANCEL' }) }}
        </b-button>
        <b-form-input
          ref="dateInput"
          v-model="dateText"
          type="text"
          class="form-control text-center mx-2"
          placeholder="YYYY-MM-DD"
          autocomplete="off"
          :state="rawDateState"
          style="max-width: 100px"
        />
        <b-button
          variant="info"
          :disabled="updateDatesDisabled"
          @click="onConfirm"
        >
          {{ translate({ path: 'GLOBAL.APPLY' }) }}
        </b-button>
      </b-form>
    </div>
  </b-dd-form>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import { BCalendar } from 'bootstrap-vue';
import { createDate, REGEX_DATE_INPUT_FORMAT } from '@/utils/dateUtils';
import useTranslation from '@/composables/useTranslation';
import { Emitter } from 'mitt';
import usePeriod from '@/composables/usePeriod';

export default defineComponent({
  name: 'InceptionDateCalendar',
  components: {
    BCalendar,
  },
  props: {
    defaultDate: {
      type: String,
      required: false,
    },
    eventBus: {
      type: Object as PropType<Emitter<Record<'on-hidden', void>>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();
    const { previousBusinessDay } = usePeriod();
    const dateInput = ref<HTMLInputElement | null>(null);
    const tempDate = ref('');

    /**
     * Prevents user from entering a date that does not exist or in the wrong format.
     */
    const rawDateState = ref<false | null>(null);

    // COMPUTED
    const selectedDate = computed({
      get() {
        return tempDate.value;
      },
      set(newVal: string) {
        dateText.value = newVal;
      },
    });

    /**
     * Text shown in input box.
     * Updates tempDate on input box value change
     */
    const dateText = ref(tempDate.value);
    watch(dateText, (newVal) => {
      const newDate = createDate(newVal);

      if (!newVal.match(REGEX_DATE_INPUT_FORMAT) || !newDate.isValid) {
        rawDateState.value = false;
        return;
      }

      rawDateState.value = null;
      tempDate.value = newVal;
    });

    watch(
      () => props.defaultDate,
      (newVal) => {
        if (!newVal) {
          dateText.value = previousBusinessDay.value;
          return;
        }
        dateText.value = newVal;
      },
      { immediate: true },
    );

    /**
     * Prevent updating dates if any input is not valid.
     */
    const updateDatesDisabled = computed(() => rawDateState.value === false);

    // METHODS
    const disableWeekends = (ymd: string, date: Date) => {
      const dayOfTheWeek = date.getDay();
      return dayOfTheWeek === 0 || dayOfTheWeek === 6;
    };

    /**
     * On dropdown closed, values should be reset to previously saved ones
     */
    const resetDatePicker = async () => {
      await nextTick();
      dateText.value = props.defaultDate ?? previousBusinessDay.value;
    };

    /**
     * Applies the new selected custom inception date
     */
    const onConfirm = async () => {
      emit('new-date', tempDate.value);
      closeDropdown();
    };

    const closeDropdown = () => {
      emit('close-dropdown');
    };

    onMounted(() => {
      props.eventBus.on('on-hidden', resetDatePicker);
    });

    onBeforeUnmount(() => {
      props.eventBus.off('on-hidden', resetDatePicker);
    });

    return {
      dateText,
      rawDateState,
      updateDatesDisabled,
      selectedDate,
      dateInput,
      disableWeekends,
      onConfirm,
      closeDropdown,
      translate,
      previousBusinessDay,
    };
  },
});
</script>
