import request from '@/api-v2/request';
import { ArticleTableBlockRequest, ArticleTableBlockResponse } from './types/ArticleTable';
import { ArticleWordcloudBlockRequest, ArticleWordcloudBlockResponse } from './types/ArticleWordcloud';
import { ArticleDTO } from './types/ArticleDTO';
import { ArticleGenericDTO } from './types/ArticleGenericDTO';
import { CmsTableBlockListResponseDTO } from './types/CmsTableBlockListResponseDTO';
import {
  AssetUploadTicketRequestDTO,
  AssetUploadTicketResponseDTO,
  ConfirmAssetUploadResponseDTO,
  ConfirmUploadTicketRequest,
} from '@/api-v2/web/cms/types/AssetUpload';

export const createArticle = (newArticle: Omit<ArticleDTO, 'id'>): Promise<ArticleDTO> =>
  request({
    url: '/cms/article',
    method: 'POST',
    data: newArticle,
  }).then((o): ArticleDTO => o.data);

export const getArticleById = (articleId: string, language: string): Promise<ArticleDTO> =>
  request({
    url: `/cms/article/${articleId}`,
    method: 'GET',
    params: { language },
  }).then((o): ArticleDTO => o.data);

export const getArticleByArticleCode = (articleCode: string, language: string): Promise<ArticleDTO> =>
  request({
    url: `/cms/authorized/article/${articleCode}`,
    method: 'GET',
    params: { language },
  }).then((o): ArticleDTO => o.data);

export const publishArticlesByIdList = (articleIdList: string[]): Promise<void> =>
  request({
    url: `/cms/article/publish`,
    method: 'PUT',
    data: articleIdList,
  }).then(
    (o) => o.data,
    (err) => {
      console.error(err);
      throw err;
    },
  );

export const unpublishArticlesByIdList = (articleIdList: string[]): Promise<void> =>
  request({
    url: `/cms/article/unpublish`,
    method: 'PUT',
    data: articleIdList,
  }).then(
    (o) => o.data,
    (err) => {
      console.error(err);
      throw err;
    },
  );

export const deleteArticlesById = (articleList: ArticleGenericDTO): Promise<void> =>
  request({
    url: '/cms/articles',
    method: 'DELETE',
    data: articleList,
  }).then(() => {});

export const updateArticle = (articles: ArticleGenericDTO): Promise<ArticleGenericDTO> =>
  request({
    url: '/cms/article',
    method: 'PUT',
    data: articles,
  }).then((o) => o.data);

export const getAuthorisedArticleListByUser = (language: string): Promise<ArticleGenericDTO> =>
  request({
    url: '/cms/authorised/articles',
    method: 'GET',
    params: { language },
  }).then((o) => o.data);

export const getAdminArticles = (language: string): Promise<ArticleGenericDTO> =>
  request({
    url: '/cms/articles',
    method: 'GET',
    params: { language },
  }).then((o) => o.data);

export const uploadAssetStep1 = (data: AssetUploadTicketRequestDTO): Promise<AssetUploadTicketResponseDTO> =>
  request({
    url: '/cms/asset/upload/step1',
    method: 'POST',
    data,
  }).then((o) => o.data);

export const uploadAssetStep2 = (data: ConfirmUploadTicketRequest): Promise<ConfirmAssetUploadResponseDTO> =>
  request({
    url: '/cms/asset/upload/step2',
    method: 'POST',
    data,
  }).then((o) => o.data);

export const getBlockTable = (query: ArticleTableBlockRequest): Promise<ArticleTableBlockResponse> =>
  request({
    url: '/cms/article/block/table',
    method: 'GET',
    params: query,
  }).then((o) => o.data);

export const listBlockTableTickers = (): Promise<CmsTableBlockListResponseDTO> =>
  request({
    url: '/cms/article/block/table/tickers',
    method: 'GET',
  }).then((o) => o.data);

export const getBlockWordcloud = (query: ArticleWordcloudBlockRequest): Promise<ArticleWordcloudBlockResponse> =>
  request({
    url: '/cms/article/block/wordcloud',
    method: 'GET',
    params: query,
  }).then((o) => o.data);

export const listBlockWordcloudTickers = (): Promise<Array<string>> =>
  request({
    url: '/cms/article/block/wordcloud/tickers',
    method: 'GET',
  }).then((o) => o.data);

export const getLatestArticleIdByCode = (code: string): Promise<string> =>
  request({
    url: `/cms/article/${code}/latest`,
    method: 'GET',
  }).then((o) => o.data);
