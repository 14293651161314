import { ApplicationDefaults } from './ApplicationDefaults';
import { ClientConstants } from './ClientConstants';

const height = 400;

const VITE_CLIENT = import.meta.env.VITE_CLIENT;

const defaultBenchmarksByCompany: {
  [client: string]: { BENCHMARK_1: string; BENCHMARK_2: string; BENCHMARK_3: string };
} = {
  [ClientConstants.PREMIALAB]: {
    BENCHMARK_1: 'SPX',
    BENCHMARK_2: 'ITRROV',
    BENCHMARK_3: 'SPTR500N',
  },
  [ClientConstants.BARCLAYS]: {
    BENCHMARK_1: 'SPX',
    BENCHMARK_2: 'BXIIMMEE',
    BENCHMARK_3: 'SPTR500N',
  },
};

const ChartDefaults: {
  HEIGHT: number;
  PERF_ATTRIB_PDF_CHART_HEIGHT: number;
  TITLE_TAG: string;
  STROKE_WIDTH: number;
  ITEM_UNDER_ANALYSIS_STROKE_WIDTH: number;
  LABEL_FONT_WEIGHT:
    | 'bold'
    | '500'
    | 'normal'
    | 'bolder'
    | 'lighter'
    | '100'
    | '200'
    | '300'
    | '400'
    | '600'
    | '700'
    | '800'
    | '900'
    | undefined;
  DATE_FORMAT: string;
  HEIGHT_STYLE: string;
  /**
   * Charts must account for axis labels. In order to center the last axis label on the last axis tick, the chart MUST
   * have some padding on the right. But this mandatory padding then affects our charts such that the right side of the
   * chart does not align with the right hand side of other content on the page. So we add a negative margin to all
   * these charts. But if we do that, then we must force the paddingRight on those charts to be exactly this value.
   * This way, the inside area of the chart will always match the boundaries of the other content on the page.
   */
  DEFAULT_SPACING_RIGHT: number;

  AXIS_LABEL_FONT_SIZE: number;
  AXIS_LABEL_PDF_FONT_SIZE: number;

  DEFAULT_COLORS: {
    /**
     * When we overwrite our app colors with whitelabel colors,
     * we may want this primary color in the charts to actually be separate
     * from the primary brand color.
     */
    primary: string;
    info: string;
    /**
     * In some cultures, red may not be the appropriate color to show
     * for a negative return.
     */
    negative: string;
    darkGrey: string;
    grey: string;
    activeReturn: string;
    bodyColor: string;
    labDefault: string;
  };
  DEFAULT_BENCHMARKS: { BENCHMARK_1: string; BENCHMARK_2: string; BENCHMARK_3: string };
} = {
  HEIGHT: height,
  PERF_ATTRIB_PDF_CHART_HEIGHT: 350,
  TITLE_TAG: 'h5',
  STROKE_WIDTH: 2,
  ITEM_UNDER_ANALYSIS_STROKE_WIDTH: 3,
  LABEL_FONT_WEIGHT: '500',
  DATE_FORMAT: 'yyyy-MM-dd',
  HEIGHT_STYLE: `height: ${height}px`,
  DEFAULT_SPACING_RIGHT: 15,

  AXIS_LABEL_FONT_SIZE: 12,
  AXIS_LABEL_PDF_FONT_SIZE: 14,

  DEFAULT_COLORS: {
    /**
     * When we overwrite our app colors with whitelabel colors,
     * we may want this primary color in the charts to actually be separate
     * from the primary brand color.
     */
    primary: ApplicationDefaults.colors.Primary,
    info: ApplicationDefaults.colors.Info,
    /**
     * In some cultures, red may not be the appropriate color to show
     * for a negative return.
     */
    negative: ApplicationDefaults.colors.NegativeReturn,
    darkGrey: '#999999',
    grey: '#A9A9A9',
    bodyColor: ApplicationDefaults.colors.BodyGray,
    labDefault: ApplicationDefaults.colors.LabDefault,
    activeReturn: ApplicationDefaults.colors.ActiveReturn,
  },
  DEFAULT_BENCHMARKS: defaultBenchmarksByCompany[VITE_CLIENT] ?? defaultBenchmarksByCompany[ClientConstants.PREMIALAB],
};

export default ChartDefaults;
