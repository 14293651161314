import { AppMain } from '@/layout/components/index';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';

// TODO: Fix width of the main content container
export const portfoliosChildrenRouter: RouteConfig[] = [
  {
    path: '',
    // leave this as DATA. if user is in a faux whitelabel, the `beforeEnter` callback will direct them properly
    redirect: { name: RouteName.DATA },
  },
  {
    name: RouteName.PORTFOLIO_FACTSHEET,
    path: ':indexUniqueIdentifier',
    components: {
      default: () => import('@/views/factsheets/PortfolioFactsheet.vue'),
      sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
      'analysis-step-toolbar': () => import('@/views/factsheets/FactsheetToolbar.vue'),
    },
    meta: {
      title: 'Portfolio Factsheet',
      shouldApplicationSidebarBeShown: true,
      // TODO: WAA-8562 Check if its still needed
      shouldPortfolioSidebarBeShown: true,
      isMainContentContainerFluid: true,
      // TODO: WAA-8562 Check if its still needed
      shouldNavigationPanelBeShown: true,
      permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
    },
  },
  {
    name: RouteName.PORTFOLIO_FACTSHEET_PDF,
    path: ':indexUniqueIdentifier/pdf',
    component: () => import('@/views/factsheets/PortfolioFactsheetPdf.vue'),
    meta: {
      title: 'Portfolio Factsheet PDF',
      pdf: true,
    },
  },
];

export const PortfoliosRouter: RouteConfig[] = [
  {
    path: '/portfolio',
    components: {
      default: AppMain,
    },
    children: portfoliosChildrenRouter,
  },
  {
    path: '/portfolios/:indexUniqueIdentifier', // LEGACY URL
    redirect(to) {
      return {
        name: RouteName.PORTFOLIO_FACTSHEET,
        params: {
          indexUniqueIdentifier: to.params.indexUniqueIdentifier,
        },
      };
    },
  },
];
